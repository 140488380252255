import { Component, OnInit } from '@angular/core';
import { ManagerService } from "src/app/services/manager.service";
import { SetupService } from "src/app/services/setup.service";
import { ExportService } from "src/app/services/export.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.sass']
})
export class WalletComponent implements OnInit {
  wallet: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  wal: any;
  id: number;
  exp: any[];
  balance = 0;
  view: boolean = false; report: boolean = false;
  tran: any;
  type: any[] = ["SMS", "COLLECTION", "CASHOUT"];
  network: any[] = ["MTN", "AIRTELTIGO", "VODAFONE"];
  saving: boolean = false; edit: boolean;
  // currency: any[] = ["GHS", "CFA"];
  sh: any;
  isChecked: boolean = true;
  response: any; search = '';
  countries: any[] = [];  res: any; 
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(
    private api: ApiService,
    // private api: ManagerService,
    private set: SetupService,
    private ext: ExportService,
    private use: NavComponent,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data) => {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.GetAllWallets(page, limit, search);
    })
    // this.GetAllWallets();
    this.getMerchants();
    this.getAllCountries();
    this.init();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') ? null : this.use.active.merchantid, type: null, code: null }
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg(
        "error",
        "Fetching Fail",
        "Unable to Fetch Merchants List"
      );
    }
    );
  }

  getSearch(page = 1, limit = 12) {
    this.api.updateUrl(page, limit, this.search);
  }



  GetAllWallets(page = 1, limit = 12, search = '') {
    this.api.get(`Wallet/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.wallet = data.data;
      this.response = data;
      console.log(data);
      // this.getTotal(data);
      setTimeout(() => {
        $(".footable").footable();
      }, 2000);
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Wallets List"
        );
      }
    );
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  editWal(data) {
    this.wal = data;
    this.edit = true;
  }

  putWallet() {
    this.wal.muserid = this.use.active.id; this.wal.mdate = new Date();
    this.api.put('Wallet/Put/' + this.wal.id, this.wal).subscribe((data: any) => {
      this.res = data; this.GetAllWallets();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Wallet Updated', 'Wallet Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Update Fail', 'Unable to Update Wallet');
    });
  }


  addWallet() {
    this.saving = true; 
    this.api.post('Wallet/Post', this.wal).subscribe((data: any) => {
      this.GetAllWallets();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Wallet Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Wallet Request Fail', err.message || 'Unable to Request Wallet');
    });
  }

  topupWallet() {
    this.saving = true;
    this.api.put('Wallet/Put', this.wal).subscribe((data: any) => {
      this.GetAllWallets();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Wallet Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Wallet Request Fail', err.message || 'Unable to Request Wallet');
    });
  }

  deleteWallet(val) {
    this.api.delete("Wallet/DeleteWallet/" + val.id).subscribe(
      (data: any) => {
        this.GetAllWallets();
        this.use.showMsg('success', 'Deleted', 'Wallet Was Deleted Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Delete Wallet"
        );
      }
    );
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  checkWallet(val) {
    const code = val.transaction_no;
    if (!code || code == 0) {
      code == val.tranid;
    }
    this.api.get("wallets/Confirm/" + code).subscribe(
      (data: any) => {
        this.GetAllWallets();
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Wallets List"
        );
      }
    );
  }

  generate() {
    this.api.post("wallets/Report", this.rpt).subscribe(
      (data: any) => {
        this.wallet = data;
        this.filtered = data;
        // this.getTotal(data);
        setTimeout(() => {
          $(".footable").footable();
        }, 2000);
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Wallets List"
        );
      }
    );
  }

  // getTotal(trans) {
  
  //   this.total = 0;
  //   trans.forEach((e: any) => {
  //     if (e.code === 1) {
  //       this.total += e.amount;
  //     }
  //   });
  //   return this.total;
  // }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "wallet");
      this.use.showMsg(
        "success",
        "Wallets Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Disputes Export Fail",
        "There is no Wallets Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id,
        TRANID: e.tranid,
        ACCOUNT: e.account,
        TYPE: e.type,
        MOBILE: e.mobile,
        NETWORK: e.network,
        SERVICE: e.service,
        AMOUNT: e.amount,
        TRANSACTION_NO: e.transaction_no,
        TRANSACTION_REF: e.transaction_ref,
        REFERENCE: e.reference,
        RESPONSE: e.response,
        CREATEDDATE: e.date,
        UPDATED: e.date,
      };
      this.exp.push(ed);
      this.id++;
    });
    return this.exp;
  }

  init() {
    // this.topup = { merchantid: this.use.active.merchantid, mobile: "", network: "", amount: "", reference: "", status_message: "PENDING", payment: true, userid: this.use.active.id, date: new Date(), mdate: new Date() };
    this.wal = {
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') ? null : this.use.active.merchantid,
      code: null,
      balance: '',
      currency: environment.currency,
      number: '',
      accNum: '',
      min_balance: 0,
      type: null,
      active: true,
      default: true,
      alert: false,
      allow: false,
      userid: this.use.active.id,
      date: new Date(),
      mdate: new Date()
    };
  }
}
