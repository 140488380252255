import { Component, OnInit } from "@angular/core";
import { ManagerService } from "src/app/services/manager.service";
import { SetupService } from "src/app/services/setup.service";
import { ExportService } from "src/app/services/export.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

declare var $: any;

@Component({
  selector: "app-refund",
  templateUrl: "./refund.component.html",
  styleUrls: ["./refund.component.sass"],
})
export class RefundComponent implements OnInit {
  refund: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  id: number;
  exp: any[];
  balance = 0;
  view: boolean = false; report: boolean = false;
  tran: any;
  search = "";
  type: any[] = ["RECEIVE", "SEND"];
  saving: boolean = false;
  ref: any;
  response: any;
  countries: any[] = [];
  res: any; edit: boolean;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.filtered = this.searchResult(value);
    // this.getTotal(this.filtered);
  }
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private set: SetupService,
    private ext: ExportService,
    private use: NavComponent
  ) { }

  ngOnInit() {
    
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data) => {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllRefunds(page, limit, search)
    })
    this.getMerchants();
    this.getAllCountries();
    this.init();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') ? null : this.use.active.merchantid, type: null, code: null }
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1, 12, this.search);
  }

  addRefund() {
    this.saving = true;
    this.api.post('Refund/Post', this.ref).subscribe((data: any) => {
      this.getAllRefunds();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Refund Add', 'Refund Created Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Refund Creation Fail', err.message || 'Unable to Create Refund');
    });
  }

  getAllRefunds(page = 1, limit = 12, search = '') {
    this.api.get(`Refund/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.refund = data.data;
      this.response = data.data;
      console.log(data)
      // this.getTotal(data);
      setTimeout(() => {
        $(".footable").footable();
      }, 2000);
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Refunds/Reversals List"
        );
      }
    );
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  editRef(data) {
    this.ref = data;
    this.edit = true;
  }

  putRefund() {
    this.ref.userid = this.use.active.id; this.ref.mdate = new Date();
    this.api.put('Refund/Put/' + this.ref.id, this.ref).subscribe((data: any) => {
      this.res = data; this.getAllRefunds();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Refund Updated', 'Refund Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Update Fail', 'Unable to Update Refund');
    });
  }

  deleteRefund(data) {
    this.api.delete("Refund/DeleteRefund/" + data.id).subscribe((data: any) => {
      this.getAllRefunds();
      this.use.showMsg('success', 'Refund Deleted', 'Refund Deleted Successfully');
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Refunds/Reversals List"
        );
      }
    );
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  update() {
    this.api.put("Refunds/Put/", + this.rpt.merchantid).subscribe(
      (data: any) => { },
      (err: HttpErrorResponse) => {
        this.use.showMsg("error", "Fetching Fail", err.message);
      }
    );
  }

  checkRefund(val) {
    const code = val.transaction_no;
    if (!code || code == 0) {
      code == val.tranid;
    }
    this.api.get("Refund/Confirm/" + code).subscribe(
      (data: any) => {
        this.getAllRefunds();
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Refunds/Reversals List"
        );
      }
    );
  }

  generate() {
    if (this.rpt.type == "SEND") {
      this.rpt.settled = true;
    }
    this.api.post("Refund/Report", this.rpt).subscribe(
      (data: any) => {
        this.refund = data.data;
        this.response = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Refunds/Reversals List"
        );
      }
    );
  }

  // getTotal(trans) {
  
  //   this.total = 0;
  //   trans.forEach((e: any) => {
  //     if (e.code === 1) {
  //       this.total += e.amount;
  //     }
  //   });
  //   return this.total;
  // }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "items");
      this.use.showMsg(
        "success",
        "Refunds/Reversals Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Refunds/Reversals Export Fail",
        "There is no Refunds/Reversals Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id,
        TRANID: e.tranid,
        ACCOUNT: e.account,
        TYPE: e.type,
        MOBILE: e.mobile,
        NETWORK: e.network,
        SERVICE: e.service,
        AMOUNT: e.amount,
        TRANSACTION_NO: e.transaction_no,
        TRANSACTION_REF: e.transaction_ref,
        REFERENCE: e.reference,
        RESPONSE: e.response,
        CREATEDDATE: e.date,
        UPDATED: e.date,
      };
      this.exp.push(ed);
      this.id++;
    });
    return this.exp;
  }
  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.refund.filter(
      (a) =>
        a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
        a.mobile.indexOf(searchString) !== -1 ||
        a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !==
        -1 ||
        a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !==
        -1 ||
        a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1
    );
  }

  init() {
    // this.topup = { merchantid: this.use.active.merchantid, mobile: "", network: "", amount: "", reference: "", status_message: "PENDING", payment: true, userid: this.use.active.id, date: new Date(), mdate: new Date() };
    this.ref = {
      // merchantid: this.use.active.merchantid,
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') ? null : this.use.active.merchantid,
      userid: this.use.active.id,
      date: new Date(), mdate: new Date(),
      code: null,
      paymentid: 0,
      description: '',
      externaltranid: 0,
      amount: '',
      type: null,
      // currency: '',
      currency: environment.currency,
      status_code: 1,
      status_message: "success",
      callbackstatus: true,
    };
  }

}
