import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from 'src/app/services/signin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  log: any; isLoginError: boolean; res: any; returnUrl: string;
  token: any; date: any;
  constructor(private api: SigninService, private router: Router, private route: ActivatedRoute, private msg: ToastrService) { }

  ngOnInit() {
    this.resetLogin();
    this.get();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log(this.returnUrl);
  }
  get() {
    this.token = this.route.snapshot.queryParams['token'] || '';
    this.date = this.route.snapshot.queryParams['date'] || '';
    this.res = this.route.snapshot.queryParams['message'] || '';
    if (this.token) {
      localStorage.setItem('fsp', JSON.stringify(this.token));
      localStorage.setItem('date', JSON.stringify(this.date));
      this.msg.success('You have been Successfully logedin to CS-Pay', 'Login');
      // setTimeout(()=>{ window.location.href = 'dashboard'; },2000);
      window.location.href = 'dashboard';
      // this.router.navigate([this.returnUrl]); location.reload(true);
    } else if (this.res) {
      this.msg.error(this.res, 'Status Message');
    }
  }

  login() {
    this.api.loginUser(this.log).subscribe((data: any) => {
      if  (data && data.access_token) {
        localStorage.setItem('fsp', JSON.stringify(data.access_token));
        localStorage.setItem('date', JSON.stringify(data.date));
        this.msg.success('You have been Successfully logedin to Your Cross Switch Merchant Portal', 'Login');
        window.location.href = this.returnUrl;
      // this.router.navigate([this.returnUrl]); location.reload(true);
      }
    }, (err: HttpErrorResponse) => {
      // this.isLoginError = true; this.res = 'Incorrect Username or Password';
      this.msg.error('Unable to login : ' + err, 'Login Unsuccessful');
    });
 }

  resetLogin(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: '', password: '' };
  }

}
