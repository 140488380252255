import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.sass']
})
export class SettlementComponent implements OnInit {
  filtered: any[]; settle: any[] = []; edit: boolean = false; set: any; 
  merchant: any; saving: boolean = false; report: boolean = false;
  payment: any[] = []; total = 0; merchants: any[] = []; rpt: any;
  id: number; exp: any[]; balance = 0;  tran: any; search = ''; response:any;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  constructor(public api: ApiService, private use: NavComponent, private route: ActivatedRoute,) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      
      this.getAllSettlement(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null };
    this.formReset();
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg( "error", "Fetching Fail", "Unable to Fetch Merchants List" );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }


  view(value) {
    this.edit = true;
    this.set = value;
    document.getElementById('settle-form').click();
  }

  cancel(value) {
    this.set = value;
    this.set.status = "Cancelled";
    this.putSettlement();
  }

  confirm(val) {
    this.api.get('Settlement/Confirm/'+ val.settlementid).subscribe((data: any) => {
      this.getAllSettlement();
      this.use.showMsg('success', 'Request confirmed', 'Settlement confirmed Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', err.message || 'Unable to Fetch Settlement List');
    });
  }

  delete(val) {
    this.api.delete('Settlement/DeleteSettlement/' + val.settlementid).subscribe(data=> {
      this.getAllSettlement();
      this.use.showMsg('success', 'Request Deleted', 'Settlement Request Deleted Successfully');
    },(err:HttpErrorResponse)=> {
      this.use.showMsg('error', 'Delete Fail', err.message || 'Unable to Delete Settlement');
    })
  }

  getAllSettlement(page=1,limit=12,search='') {
    this.api.get(`Settlement/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any)  => {
      this.settle = data.data; 
      this.response = data;
      console.log(data)
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Settlement List Fail', err.message || 'Unable to get Settlement List');
    });
  }

  postSettlement() {
    this.saving = true;
    this.api.post('Settlement/Post', this.set).subscribe((data: any) => {
      this.getAllSettlement();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Settlement Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Settlement Request Fail', err.message || 'Unable to Request Settlement');
    });
  }

  putSettlement() {
    this.api.put('Settlment/Put/' + this.set.settlementid, this.set).subscribe((data: any) => {
      this.getAllSettlement();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Updated', 'Settlement Request updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Request Update Fail', err.message || 'Unable to Update Settlement Request');
    });
  }

  selected(event) {
    this.filtered = this.settle.filter(a => a.merchantid == event.target.value);
  }

  searchResult(searchString: string) {
    return this.settle.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  formReset() {
    this.set = { code: null, title: '', start: '', end: '',
      charge: 0, status: 'Pending', amount: 0, period: 0, merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid,
      userid: this.use.active.id, date: new Date()
    };
    this.edit = false;
  }

}
