import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild('invoiceForm') public any: NgForm; public files: any[];
  edit: boolean; item: any; id: any; merchant: any; items: any[] = []; total = 0; p: any;
  image = environment.imageUrl + 'Invoice/'; public regex = new RegExp(' ','g'); rpt: any; 
  customers: any[] = []; filtered: any[] = []; saving: boolean = false; cust: any; 

  // tslint:disable-next-line:max-line-length
  constructor(private api: ManagerService, private set: SetupService, private router: Router, private rout: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.id = this.rout.snapshot.paramMap.get('id');
    this.getMerchant();
    this.generateSlug();
    this.newCustomer()
    this.getAllCustomer();
    // this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, name: null, code: 0 }
  }

  getAll(id) {
    if (id && id !== 0) {
      this.edit = true;
      this.getInvoice(id);
    }
  }

  async addSlug(event) {
    if(event.target.value != null && !this.item._id && this.item.slug != null) { this.item.slug = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  generateSlug(){
    this.api.get('Sequence/GetCode/slug').subscribe((data:string) => {
      this.item.slug = data
    })
  }

  validateSlug(val){
    this.api.get('Invoice/Get/'+val).subscribe((data:any)=>{
      if(data.data) this.use.showMsg('error','url validation failed','slug already exist');
    }); 
  }

  getInvoice(id) {
    this.api.get('Invoice/Get'+id).subscribe((data: any) => {
      this.item = data;
      this.items = JSON.parse(this.item.description);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Invoice Detail');
    });
  }

  getMerchant() {
    this.api.get('merchant').subscribe((data: any) => {
      this.merchant = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }


  fileUpload(event) {
    this.api.Upload(event.target.files[0], 'Invoices').subscribe((data: any) => {
      this.item.image = this.image + data; 
      // console.log(this.image, data);
      // console.log(this.item.image);
    });
  }

  postInvoice() {
    this.item.item = JSON.stringify(this.items);
    this.item.items = this.items;
    this.api.post('Invoice/Post',this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Invoice Added', 'New Invoice Added Successfully');
      this.router.navigate(['commerce/invoice']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Add Invoice Detail');
    });
  }

  putInvoice() {
    this.item.item = JSON.stringify(this.items); 
    this.item.items = this.items;
    this.api.put('Invoice/Post'+this.item.itemid, this.item).subscribe((data: any) => {
      this.item = data; this.formReset(this.any);
      this.use.showMsg('success', 'Invoice Updated', 'Invoice Details Updated Successfully');
      this.router.navigate(['invoice']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Add Fail', 'Unable to Fetch Invoice Detail');
    });
  }

  getTotal() {
    // console.log(this.items);
    this.total = 0;
    this.items.forEach((e: any) => {
        this.total += e.amount;
    });
    return this.total.toFixed(2);
  }

  getAllCustomer() {
    this.api.get('Customers').subscribe((data: any) => {
      this.customers = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer List');
    });
  }

  addCustomer() {
    this.saving = true;
    this.api.post('Customer/Post', this.cust).subscribe((data: any) => {
      this.getAllCustomer();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Customer Add', 'Customer Created Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Customer Creation Failed', err.message || 'Unable to Create Customer');
    });
  }

  addItem() {
    this.p.amount = this.p.unit_price * this.p.quantity;
    this.items.push(this.p); this.item.amount = this.getTotal();
    this.init();
  }

  remove(val, i) {
    // this.images.slice(i, i+1);
    this.items = this.arrayRemove(this.items, val.name);
    this.item.amount = this.getTotal();
  }

  arrayRemove(arr, val) {
    return arr.filter((ele)=>{
        return ele.name != val;;
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      // const merch = this.use.active.merchantid || '';
      this.item = { image: 'assets/img/acyst-logo.png',
        invoiceid: 0, code: null, name: "", email: "", mobile: "", amount: 0, sms: false,
        description: "", slug: "", status: 'Pending', items: [], item: '',
        merchantid: this.use.active.merchantid, userid: this.use.active.id, date: new Date()
      };
      this.init();
    }
  }

  init() {
    this.p = {
      name: '', amount: '', unit_price: 0, quantity: 0
    }
  }

  newCustomer() {
    this.cust = { 
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, 
      code: null, 
      name: '', 
      duedate:'', 
      email: '', 
      mobile: '',  
      userid: this.use.active.id, 
      date: new Date(), 
      mdate: new Date()};
  }
}
