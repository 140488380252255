
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.sass']
})
export class BillsComponent implements OnInit {
  @ViewChild('billForm') public any: NgForm;
  bills: any; filtered: any[] = []; types: any[] = ["ONETIME","DAILY","WEEKLY","MONTHLY"];
  response: any; search: string =''; st: any; id = 0; exp: any; merchants: any[] = [];  rpt: any; 
  b: any;  saving: boolean = false; view: boolean = false; report: boolean = false; tran: any; total = 0; customers: any[] = [];
  private _searchTitle: string;

  get searchTitle(): string {
    return this._searchTitle;
  }
  set searchTitle(value: string) {
    this._searchTitle = value;
    this.search = this._searchTitle;
  }

  constructor(private api: ApiService, private route: ActivatedRoute, private use: NavComponent, public ext: ExportService, private set: SetupService, private _ext: ExportService,) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getBills(page,limit,search);
    })
    this.formReset();
    this.getAll();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null };
    if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') this.getMerchants();
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },(err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }
  getAll() {
    this.api.get('customer/getall?limit=5').subscribe((data: any) => {
      this.customers = data.data; //this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customers');
    });
  }

  getCustomers(event) {
    if(event && event.term && event.term.length >3) {
      const value = event.term;
      this.api.get('customer/getall?limit=10&search='+value).subscribe((data: any) => {
        this.customers = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Search for Customers');
      });
    }
  }

  Selected(){
    this.api.get('customer/get/'+this.b.customerid).subscribe((data: any) => {
      const cust = data.data;
      this.b.name = cust.name; this.b.mobile = cust.mobile; this.b.email = cust.email;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to FetchCustomer Details');
    });
  }

  Resent(val){
    this.api.get('Bill/Resent/'+val.code).subscribe((data: any) => {
      const cust = data.data;
      this.b.name = cust.name; this.b.mobile = cust.mobile; this.b.email = cust.email;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to FetchCustomer Details');
    });
  }

  getSearch(page=1, limit=10) {
    this.api.updateUrl(page,limit,this.search);
  }

  getBills(page,limit,search) {
    this.api.get(`Bill/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Bills List');
    });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    this.api.post('Bill/Report', this.rpt).subscribe((data: any) => {
      this.bills = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Bills List');
    });
  }

  
  getTotal(trans) {
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  addBill() {
    this.saving = true;
    this.api.post('Bill/Post', this.b).subscribe((data: any) => {
      this.ngOnInit();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Bill Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Bill Request Fail', err.message || 'Unable to Request Bill');
    });
  }

  Update() {
    this.api.put('Bill/Put/'+this.b.id, this.b).subscribe((data: any) => {
      this.use.showMsg('success', 'Debt Request Uploaded', 'Debt has been requested successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  disable(val) {
    val.status = "Cancel";
    this.api.put('Bill/'+val.billid, val).subscribe((data: any) => {
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Invoice Canceling Failed', 'Unable to Cancel Invoice');
    });
  }

  searchResult(searchString: string) {
    return this.bills.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'bills');
      this.use.showMsg('success', 'Invoices Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Invoices Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.customer.name, MOBILE: e.customer.mobile, EMAIL: e.customer.email, OTHER: e.other, 
        AMOUNT: e.amount, AMOUNT_PAID: e.amount, ACTIVE: e.active, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.b = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, code: null, number: '1234', type: 'DEFAULT', frequency:'ONETIME', new: false, name: '', email: '', amount: '', amount_paid: 0, mobile: '', currency: environment.currency, expiry: 30, description: '', status: 'Pending', active: true, userid: this.use.active.id, date: new Date(), mdate: new Date()};
    }
  }
  

}
