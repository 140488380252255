import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-setup-industry',
  templateUrl: './setup-industry.component.html',
  styleUrls: ['./setup-industry.component.sass']
})
export class SetupIndustryComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; 
  industry: any; industries: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;user: any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }

  constructor(private route: ActivatedRoute,private api: ApiService, private _use: NavComponent, private _exp: ExportService) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    // this.getAllCharge();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllIndustries(page,limit,search);
    })
  }

  getAllIndustries(page=1,limit=10,search='') {
    this.api.get(`Industry/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.industries = data.data; this.response = data;
      this._use.showMsg('success', 'Industry List', 'Fetched Industry list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Industry List'); });
  }

  getMore(page,limit) {
    this.api.get('Industry/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.industries = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Industry List Fail', 'Unable to Fetch Industries List');
    });
  }

  editIndustry(data) {
    this.industry = data;
    this.edit = true;
  }
  delIndustry(dat) {
    this.api.delete('Industry/Delete/'+dat.id).subscribe((data: any) => {
      this.res = data; this.getAllIndustries();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Industry'); 
    });
  }

  saveIndustry() {
    if(this.industry.id) {
      this.putIndustry();
    } else {
      this.postIndustry();
    }
  }

  postIndustry() {
    this.api.post('Industry/Post', this.industry).subscribe((data: any) => {
      this.getAllIndustries();
      this.formReset();
      this._use.showMsg('success', 'Industry Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Industry');
    });
  }

  putIndustry() {
    this.industry.muserid = this._use.active.id; this.industry.mdate = new Date();
    this.api.put('Industry/Put/'+this.industry.id, this.industry).subscribe((data: any) => {
      this.getAllIndustries(); this.formReset();
      this._use.showMsg('success', 'Industry Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.industries, 'Industry');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.industry = {
        industryId: 0,
        name: '',
        code: null, 
        active: true,
       
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }


}
