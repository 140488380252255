import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.sass']
})

export class SetupAccountComponent implements OnInit {
  @ViewChild('accountForm') public any: NgForm;
  edit: boolean; list: boolean;
  account: any; accounts: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string; user: any;
  countries: any[] = []; merchants: any[] = [];  banks: any[] = [];
  // types: any[] = ["SEND", "RECEIVE", "CARD", "SMS"];
  types: any[] = ["BANK", "MOMO"];

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1, 10)
  }

  constructor(private route: ActivatedRoute, private api: ApiService, private _use: NavComponent, private _exp: ExportService) { }


  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    // this.getAllCharge();
    this.route.queryParams.subscribe((data) => {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllAccounts(page, limit, search);
    });
    this.getAllCountries();
    this.getMerchant();
    this.getBanks();
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Currency List');
    });
  }

  getBanks() {
    this.api.get('Bank/GetAll').subscribe((data: any) => {
      this.banks = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Bank List');
    });
  }


  getMerchant() {
    this.api.get('Merchant').subscribe((data: any) => {
      this.merchants = data;
    },
      (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }


  getAllAccounts(page = 1, limit = 10, search = '') {
    this.api.get(`Account/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.accounts = data.data; this.response = data;
      this._use.showMsg('success', 'Accounts List', 'Fetched Accounts list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Accounts List');
    });
  }

  getMore(page, limit) {
    this.api.get('BaAccountnk/GetAll?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.accounts = data.data; this.response = data;;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Bank List Fail', 'Unable to Fetch Accounts List');
    });
  }

  editIndustry(data) {
    this.accounts = data;
    this.edit = true;
  }
  delIndustry(dat) {
    this.api.delete('Account/Delete/' + dat.id).subscribe((data: any) => {
      this.res = data; this.getAllAccounts();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Account');
    });
  }

  saveBank() {
    if (this.account.id) {
      this.putBank();
    } else {
      this.postBank();
    }
  }

  postBank() {
    this.api.post('Account/Post', this.account).subscribe((data: any) => {
      this.getAllAccounts();
      this.formReset();
      this._use.showMsg('success', 'Account Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Account');
    });
  }

  putBank() {
    this.account.muserid = this._use.active.id; this.account.mdate = new Date();
    this.api.put('Account/Put/' + this.account.id, this.account).subscribe((data: any) => {
      this.getAllAccounts(); this.formReset();
      this._use.showMsg('success', 'Account Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.account, 'Bank');
  }

  formReset() {

    this.account = {
      merchantid: (this._use.active.typ === 'Admin' || this._use.active.typ === 'Support') ? null : this._use.active.merchantid,
      type: null,
      bankId: null,
      currency: null,
      accountname: '',
      accountnumber: '',
      active: true,
      default: true,
    }
    this.edit = false;
  }

  back() {
    this.formReset();
  }


}
