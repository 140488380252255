import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { ApiService } from 'src/app/services/api.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public active: any; res: any; public date: any; timeout = false; timer = 0; bal: any;
  brn: any = { merchantid: '',smsbalance: 0, balance: 0, charge: 0, smscharge: 0 };
  constructor(private ser: SigninService, private _nav: Router, public api: ApiService, private userIdle: UserIdleService) { }

  ngOnInit() {
    this.init();
    this.active = this.api.user();
    this.api.active = this.active;
    this.date = JSON.parse(localStorage.getItem('Date'));
    console.log(this.active);
    // if(this.active.typ != 'Admin' && this.active.typ != 'Support') this.getMerchant(this.active.merchantid);
    this.getClaims();
    // if(this.active && this.active.code) this.getBalance(this.active.code);

    //Start watching for user inactivity.
    this.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => { 
      // console.log(count); 
      this.timeout = true;
      
      if(this.timer == 0 || this.timer == 5){
        setTimeout(() => {
          this.api.showMsg('warning', 'Connection Deactivated', 'you have been idle for 20 minutes u will be Disconnected from server in 10second');
        });
        this.timer=0
      }
      this.timer +=1;
    });
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => { 
      // console.log('Time is up!'); 
      this.logOut(); 
    });
    this.userIdle.ping$.subscribe(() => {
      // console.log("PING");
      this.getRefreshToken();
    });
  }

  ngOnDestroy() {
    this.stop();
    this.stopWatching();
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
    this.timeout = false;
  }

  showMsg(type, title, message) {
    // this.api.showMsg(type,title,message);
    // if (type === 'success') {
    //   this.api.toastr.success(message, title);
    // } else if (type === 'warning') {
    //   this.toastr.warning(message, title);
    // } else if (type === 'error') {
    //   this.toastr.error(message, title);
    // }
  }

  getClaims() {
    this.ser.getActiveUser().subscribe((data: any) => {
      this.api.activeuser = data.data; this.active = data.data;
      // this.showMsg('success', 'Claims', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  getRefreshToken() {
    this.ser.getRefresh().subscribe((data: any) => {
      localStorage.setItem('fsp', JSON.stringify(data.access_token));
    }, (err: HttpErrorResponse) => { /*this._nav.navigate(['/login']);*/ });
  }

  getMerchant(val) {
    this.api.get('merchant/get/'+val).subscribe((data: any) => {
      this.brn = data; // console.log(this.brn);
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getBalance(val) {
    this.api.get('paynow/getbalance/'+val).subscribe((data: any) => {
      this.bal = data; // console.log(this.brn);
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }


  logOut() {
    this.ser.logOut(this.active.unique_name).subscribe((data: any) => {
      this.res = data; localStorage.removeItem('fsp'); // alert('Logout successfully');
      this.showMsg('success', 'Logout', 'You hav been Logout successfully @' + this.active.fullname);
      this._nav.navigate(['/login']);
    }, (err: HttpErrorResponse) => {;
      localStorage.removeItem('fsp');
      this._nav.navigate(['/login']);
    });
  }

  init() {
    this.active = { given_name: '', image: '', typ: '', };
  }

}
