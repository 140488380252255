import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-sequence',
  templateUrl: './setup-sequence.component.html',
  styleUrls: ['./setup-sequence.component.css']
})
export class SetupSequenceComponent implements OnInit {

  @ViewChild('sequenceForm') public any: NgForm;
  edit: boolean; list: boolean; seq: any; sequence: any[]; res: any; response: any; search: string = '';

  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportService) { }

  ngOnInit() {
    this.formReset();
    this.getAllSequence();
  }

  getAllSequence() {
    this.api.get('Sequence/GetAll').subscribe((data: any) => {
      this.sequence = data.data; this.response = data; console.log(data)
      this.use.showMsg('success', 'Sequence List', 'Fetched Sequence list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Sequence List'); });
  }

  getMore(page,limit) {
    this.api.get('Sequence/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.sequence = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Sequence List Fail', 'Unable to Fetch Sequence List');
    });
  }

  editSeq(data) {
    this.seq = data;
    this.edit = true;
  }

  // delSeq(dat) {
  //   this.api.delete('Sequence/Delete/'+dat.sequenceid).subscribe((data: any) => {
  //     this.res = data; this.sequence.slice(dat);
  //   }, (err: HttpErrorResponse) => { alert(err.statusText); });
  // }

  delSeq(data) {
    this.api.delete("Sequence/Delete/" + data.sequenceid).subscribe(
      (data: any) => {
        this.getAllSequence();
        this.use.showMsg('success', 'Deleted', 'Sequence Was Deleted Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Delete Sequence"
        );
      }
    );
  }

  saveSequence() {
    if(this.seq.sequenceid) {
      this.putSequence();
    } else {
      this.postSequence();
    }
  }

  postSequence() {
    this.api.post('Sequence/Post',this.seq).subscribe((data: any) => {
      this.getAllSequence(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Sequence Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Sequence');
    });
  }

  putSequence() {
    this.seq.muserid = this.use.active.id; this.seq.mdate = new Date();
    this.api.put('Sequence/Put/'+this.seq.sequenceid, this.seq).subscribe((data: any) => {
      this.getAllSequence(); this.edit = false;
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Sequence Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.seq = { sequenceid: 0, name: '', prefix: '', counter: '', length: 0,
      userid: this.use.active.id, muserid: null, date: new Date(), 
      mdate: new Date()  };
    }
  }

  Export() {
    this._exp.exportAsExcelFile(this.sequence, 'sequence');
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false; this.edit = false;
  }

   // getAllSequence() {
  //   this.api.getSequences().subscribe((data: any) => {
  //     this.sequence = data;
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Sequence List'); });
  // }

  // editSeq(data) {
  //   this.seq = this.sequence.find(s => s.sequenceid === data);
  //   this.seq = data;
  //   this.edit = true; this.list = true;
  // }

  // delSeq(dat) {
  //   this.api.delSequence(dat.sequenceid).subscribe((data: any) => {
  //     this.res = data; this.getAllSequence();
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Sequence'); });
  // }

  // postSequence() {
  //   this.api.postSequence(this.seq).subscribe((data: any) => {
  //     this.res = data; this.sequence.push(data);
  //     this.formReset(this.any); this.list = false;
  //     this._use.showMsg('success', 'Sequence Added', 'Added to list Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Sequence');
  //   });
  // }

  // putSequence() {
  //   this.seq.muserid = this._use.active.id; this.seq.mdate = new Date();
  //  this.api.putSequence(this.seq.sequenceid, this.seq).subscribe((data: any) => {
  //     this.res = data; this.getAllSequence(); 
  //     this.formReset(this.any); this.back();
  //     this._use.showMsg('success', 'Sequence Updated', 'Details Updated Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
  //   });
  // }

}
