import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SigninService {
  helper = new JwtHelperService();
  private api = environment.apiUrl;
  constructor(private http: HttpClient, public jwtHelper: JwtHelperService) { }

  userDetails() {
    return this.helper.decodeToken(this.jwtHelper.tokenGetter());
  }

  loginUser(data): Observable<any> {
    return this.http.post(this.api + 'auth', JSON.stringify(data));
  }

  changePassword(data): Observable<any> {
    return this.http.post(this.api + 'auth/changepassword', JSON.stringify(data));
  }

  getClaim(): Observable<any> {
    return this.http.get(this.api + 'auth');
  }

  getActiveUser(): Observable<any> {
    return this.http.get(this.api + 'auth/active');
  }

  getRefresh(): Observable<any> {
    return this.http.get(this.api + 'auth/refresh');
  }

  Reset(email): Observable<any> {
    return this.http.get(this.api + 'auth/reset/' + email);
  }

  Disabled(id): Observable<any> {
    return this.http.get(this.api + 'auth/disable/' + id);
  }

  resetUser(data): Observable<any> {
    return this.http.post(this.api + 'auth/reset/', JSON.stringify(data));
  }

  logOut(user: string) {
    // localStorage.removeItem('Kendru');
    return this.http.get(this.api + 'auth/logout/' + user);
  }

}
