import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

declare var $: any;
@Component({
  selector: 'app-bulkpay',
  templateUrl: './bulkpay.component.html',
  styleUrls: ['./bulkpay.component.sass']
})
export class BulkpayComponent implements OnInit {
  payment: any[] = []; total = 0; balance = 0; merchants: any[] = []; pay: any; charge: any; exp: any[]; id: number;
  type: any[] = [ "MOMO", "BANK", "AIRTIME", "ECG", "GWC" ]; edit: boolean = false; saving: boolean = false; rpt: any;  response:any; search = '';

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private ext: ExportService, public use: NavComponent, private route: ActivatedRoute,) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    // this.route.queryParams.subscribe((data)=> {
    //   ;
    //   const page = data.page ? data.page : 1;
    //   const limit = data.limit ? data.limit : 12;
    //   const search = data.search ? data.search : '';
      
    //   this.getAll(page,limit,search) 
    // })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null }
    this.formReset();
    if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  formReset() {
    this.pay = { merchantid: this.use.active.merchantid, amount: 0, charge: 0, total: 0, type: 'SEND', data: [], payment: true, userid: this.use.active.id, date: new Date(), mdate: new Date()  };
    this.balance = this.use.brn.smsbalance;
    this.saving = false;
    // this.getAll();
    this.payment = [{ name: 'Harmony Alabi', mobile: '+233502666774', amount: 300, email: 'info@paynowafrica.com', network: 'VODAFONE', reference: 'Data Allowance' }];
    // if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }


  postBulkPay() {
    this.saving = true;
    this.api.post('payment/bulk',this.pay).subscribe((data: any) => {
      this.formReset(); 
      this.use.showMsg('success', 'Request Sent', 'Payment Request send Successfully');
    }, (err) => {
      this.saving = false; 
      
      this.use.showMsg('error', 'Bulk Pay Fail', err.message || 'Unable to Send Bulk Pay Request');
    });
  }

  read(event) {
    // this.img = event.target.files[0];
    if(event.target.files[0]) {
      this.ext.readExcel(event.target.files[0]);
      setTimeout(() => { 
        // this.upload = this.ext.import
        this.pay.data = this.formatReads(this.ext.import);
        console.info(this.exp);
        setTimeout(() => {
          this.pay.total = this.getTotal(this.pay.data);
          // $('.footable').footable();
        }, 3000);
      }, 3000);
    }else {
      this.use.showMsg('error', 'File Selection', 'Please Select a valid Excel file');
    }
  }

  getTotal(trans) {
    this.total = 0; this.pay.amount = 0; this.pay.charge = 0;
    trans.forEach((e: any) => {
      // if (e.code === 1) {
        this.total += e.amount + e.charge;
        this.pay.amount += e.amount;
        this.pay.charge += e.charge;
      // }
    });
    this.pay.amount = this.pay.amount.toFixed(2);
    this.pay.charge = this.pay.charge.toFixed(2);
    return this.total;
  }

  export() {
    // if (this.payment.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'paynow-bulkpay-sample');
      this.use.showMsg('success', 'Bulk-Pay Template Downloaded', 'Check Downloaded Excel File For Details');
    // } else {
    //   this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    // }
  }

  format() {
    this.id = 1; this.exp = [];
    this.payment.forEach((e: any) => {
      const ed = {
        SN: this.id, NAME: e.name, MOBILE: e.mobile, NETWORK: e.network, EMAIL: e.email,
        AMOUNT: e.amount, REFERENCE: e.reference, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  formatReads(values) {
    this.id = 1; this.exp = [];
    values.forEach((e: any) => {
      const ed = {
        SN: this.id, name: e.NAME, email: e.EMAIL || 'info@paynowafrica.com', mobile: e.MOBILE, network: e.NETWORK,
        charge: this.getCharge(parseInt(e.AMOUNT)),
        amount: e.AMOUNT, reference: e.REFERENCE, CREATEDDATE: new Date()
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  getCharge(amount) {
    if(this.charge) {
    var charges = 0;
      if(this.charge.percentage) {
        charges = (amount * this.charge.value) / 100;
        if(charges < this.charge.minimum) charges = this.charge.minimum;
        if(charges > this.charge.maximum) charges = this.charge.maximum;
      } else {
        charges = this.charge.amount;
      }
      // console.log(charges)
      return charges;
    }
    return 0;
  }

}
