import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-payment-pages',
  templateUrl: './payment-pages.component.html',
  styleUrls: ['./payment-pages.component.sass']
})
export class PaymentPagesComponent implements OnInit {

  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = [ "RECEIVE", "SEND" ]; view: boolean = false; report: boolean = false; tran: any; response:any; search = ''; page: any;
  saving: boolean = false; isShowDiv = true; edit: boolean; res: any;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
 
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      this.getAllPages(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null, code: null }
    this.getMerchants();
    this.init();
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllPages(page=1,limit=12,search='') {
      this.api.get(`Page/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.payment = data; this.filtered = data.data; console.log(data); this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment Pages List');
    });
  }

  generateSlug(){
    this.api.get('Sequence/GetCode/slug').subscribe((data:string) => {
      this.page.slug = data
    })
  }

  validateSlug(val){
    this.api.get('page/get/'+val).subscribe((data:any)=>{
      if(data.data) this.use.showMsg('error','url validation failed','slug already taken');
    }); 
  }

  savePage() {
    if(this.page.id > 0){
      this.updatePage()
    }else{
      this.newPage()
    }
  }

  editPage(data) {
    this.page = data;
    this.edit = true;
  }


  newPage() {
    this.saving = true;
    this.api.post('Page/Post', this.page).subscribe((data: any) => {
      this.getAllPages();
      document.getElementById('close').click();
      this.use.showMsg('success', 'New Page Add', 'Page Created Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Page Creation Failed', err.message || 'Unable to Create New Page');
    });
  }

  delPage(data) {
    this.api.delete("Page/DeletePage/" + data.id).subscribe((data: any) => {
      this.getAllPages();
      this.use.showMsg('success', 'Page Deleted', 'Page Deleted Successfully');
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg( "error", "Fetching Fail", "Unable to Fetch Page List");
      }
    );
  }

  updatePage() {
    this.page.userid = this.use.active.id; this.page.mdate = new Date();
    this.api.put('Page/Put/' + this.page.id, this.page).subscribe((data: any) => {
      this.res = data; this.getAllPages();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Page Updated', 'Page Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Update Fail', 'Unable to Update Page');
    });
  }

  generate() {
    this.api.post('Pages/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment Pages List');
    });
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Payment Pages Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Payment Pages Export Fail', 'There is no Payment Pages Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }

  init() {
    this.page = { 
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, 
      code: '', 
      slug:'',
      amount:'',
      title: '', 
      description: '', 
      image: '', 
      url: '', 
      message: '',  
      email: '',  
      redirecturl: '',  
      userid: this.use.active.id, 
      active: true,
      advanced: true,
      muserid:'',
      merchant:'',
      date: new Date(), 
      mdate: new Date()};

  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

}
