import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRefundComponent } from './view-refund/view-refund.component';
import { RefundComponent } from './refund.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { RecurringRoutingModule } from '../../recurring/recurring-routing.module';

@NgModule({
  declarations: [RefundComponent,ViewRefundComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RecurringRoutingModule,
    NgSelectModule,
  ]
})
export class RefundModule { }
