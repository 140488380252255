import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {

  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = ["RECEIVE", "SEND"]; view: boolean = false; report: boolean = false; tran: any; response: any;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string; search: string = '';

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
    // this.getTotal(this.filtered);
  }
  constructor(private api: ManagerService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllPayments(page,limit,search);
    });
    this.rpt = { merchantid: this.use.active.merchantid, type: '', start: '', end: '', settled: false, code: 0 }
    this.getAllPayment();
    // this.getMerchant();
  }

  getMerchant() {
    this.set.getCompanys().subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllPayments(page=1,limit=12,search='') { 
    this.api.get(`Payment/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.payment = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }
  getAllPayment() {
    this.api.get('Payment').subscribe((data: any) => {
      this.payment = data; this.filtered = data; 
      // this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getTranStatus(val) {
    this.api.get('Integration/v1/GetTrans?order_id='+val.tranid).subscribe((data: any) => {
      // this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  deletePayment(val) {
    this.api.get('Payment/' + val.paymentid).subscribe((data: any) => {
      this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('Payment/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; 
      // this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  // getTotal(trans) {
  
  //   this.total = 0;
  //   trans.forEach((e: any) => {
  //     if (e.code === 1) {
  //       this.total += e.amount;
  //     }
  //   });
  //   return this.total;
  // }

  export() {
    if (this.filtered.length > 0) {
      // const data = this.format();
      this.ext.exportAsExcelFile(this.filtered, 'items');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1) !== -1);
  }

}
