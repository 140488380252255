import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.sass']
})
export class TopupComponent implements OnInit {
  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  types: any[] = [ "TITHE", "OFFERING", "DONATION", "RECEIVE", "SEND" ]; edit: boolean = false; response: any;
  topup: any; saving: boolean = false; report: boolean = false; search = ''; wallets: any[] = []; res: any; company: any[] = [];
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: '', start: '', end: '', settled: false, code: null };
    this.formReset();
    this.getAllTopup();
    this.GetAllWallets();
    if(this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') { this.getMerchant(); }
  }

  formReset() {
    this.topup = {
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid,
      mobile: "", 
      network: null, 
      email: "", 
      charge: "", 
      method: null, 
      accountnumber:"",
      bank:"",
      cardnumber:"",
      expiry:"",
      cvv:"",
      amount: "", 
      walletid: null, 
      reference: "", 
      status_message: "PENDING", 
      payment: true, 
      userid: this.use.active.id, 
      date: new Date(), 
      mdate: new Date()  };
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  GetAllWallets() {
    this.api.get(`Wallet/GetAll`).subscribe((data: any) => {
      this.wallets = data.data;
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Wallets List"
        );
      }
    );
  }

  postTopup() {
    this.saving = true;
    this.api.post('Topup',this.topup).subscribe((data: any) => {
      this.getAllTopup();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Topup Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Topup Request Fail', err.message || 'Unable to Request Topup');
    });
  }

  delete(val) {
    this.api.delete("Topup/" + val.id).subscribe(
      (data: any) => {
        this.GetAllWallets();
        this.use.showMsg('success', 'Deleted', 'Wallet Was Deleted Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Delete Wallet"
        );
      }
    );
  }


  getAllTopup(page=1,limit=12,search='') {
      this.api.get(`Topup?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data); this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Topup List');
    });
  }

  generate() {
    this.api.post('Topup/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Topup List');
    });
  }

  getTotal(trans) {
    
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, MOBILE: e.mobile, NETWORK: e.network,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.status_message, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }

}
