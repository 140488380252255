import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SigninService } from 'src/app/services/signin.service';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: any; dash: any; cp: any; transactions: any[] = []; merchants: any[] = []; rpt: any;
  constructor(private ser: SigninService, private _api: SetupService, public toastr: ToastrService, private api: ApiService,  public use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.user = this.ser.userDetails(); console.log(this.user);
    this.formReset();
    this.Dashboard();
    if(this.user.typ === 'Admin' || this.user.typ === 'Support'){ this.getMerchants(); }
    this.rpt = { code: this.use.active.code}
  }

  getMerchants() {
    this.api.get('merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },(err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  Dashboard() {
    this.api.get('dashboard/getcount').subscribe((data: any) => {
      this.dash = data;
    
      this.api.get('dashboard/getsend').subscribe((data: any) => {
        this.dash.send = data
      }, (err: HttpErrorResponse) => {
        this.api.showMsg('error', 'Error', 'Error fetching Send Transaction Summary');
      });
      this.api.get('dashboard/getreceive').subscribe((data: any) => {
        this.dash.receive = data
      }, (err: HttpErrorResponse) => {
        this.api.showMsg('error', 'Error', 'Error fetching Send Transaction Summary');
      });
      this.api.get('dashboard/getsettlement').subscribe((data: any) => {
        this.dash.settlements = data
      }, (err: HttpErrorResponse) => {
        this.api.showMsg('error', 'Error', 'Error fetching Send Transaction Summary');
      });
    }, (err: HttpErrorResponse) => {
      this.api.showMsg('error', 'Error', 'Error fetching dashboard data');
    });
    // this._api.getDashboard().subscribe((data: any) => {
    //   this.dash = data;
    // }, (err: HttpErrorResponse) => {
    //   this.api.showMsg('error', 'Error', 'Error fetching dashboard data');
    // });
  }
  
  getDashboard(event) {
    console.log(event)
    if(event){
      this.api.get('dashboard?code='+event).subscribe((data: any) => {
        this.dash = data;
        this.api.get('dashboard/getsend?code='+event).subscribe((data: any) => {
          this.dash.send = data
        }, (err: HttpErrorResponse) => {
          this.api.showMsg('error', 'Error', 'Error fetching Send Transaction Summary');
        });
        this.api.get('dashboard/getreceive?code='+event).subscribe((data: any) => {
          this.dash.receive = data
        }, (err: HttpErrorResponse) => {
          this.api.showMsg('error', 'Error', 'Error fetching Collection Transaction Summary');
        });
        this.api.get('dashboard/getsettlement?code='+event).subscribe((data: any) => {
          this.dash.settlements = data
        }, (err: HttpErrorResponse) => {
          this.api.showMsg('error', 'Error', 'Error fetching Settlement Transaction Summary');
        });
      }, (err: HttpErrorResponse) => {
        this.api.showMsg('error', 'Error', 'Error fetching dashboard data');
      });
    }
  }

  postPassword() {
    this.ser.changePassword(this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.api.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }


  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dash = { sequence: 0, smsapi: 0, smslog: 0, alert: 0, receive: null, send: null, settlements: null, payment: null, failed: null };
      this.cp = {
        username: this.user.unique_name, oldpassword: '', newpassword: '', image: '',
        muserid: this.user.Id, mdate: new Date()
      };
    }
  }
  init() {
    this.user = { given_name: '', mobile: '', image: '', typ: '', email: '' };
  }

}
