import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecurringRoutingModule } from './recurring-routing.module';
import { RecurringComponent } from './recurring.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { PlansComponent } from './plans/plans.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionDetailsComponent } from './subscriptions/subscription-details/subscription-details.component';
import { PlanDetailsComponent } from './plans/plan-details/plan-details.component';
import { SubscriberDetailsComponent } from './subscribers/subscriber-details/subscriber-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { PaginationComponent } from 'src/app/traits/pagination/pagination.component';

@NgModule({
  declarations: [RecurringComponent, SubscribersComponent, SubscriptionsComponent,PlansComponent, SubscriptionDetailsComponent, PlanDetailsComponent, SubscriberDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RecurringRoutingModule,
    NgSelectModule,
  ],
  exports: [RecurringComponent],
})
export class RecurringModule { }
