import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-view-settlement',
  templateUrl: './view-settlement.component.html',
  styleUrls: ['./view-settlement.component.sass']
})
export class ViewSettlementComponent implements OnInit {

  set: any; id: string; rpt: any;  trans: any[] = []; response: any; 

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }

  constructor(
    private api: ApiService, 
    private router: ActivatedRoute,
    private use: NavComponent,
    ) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getSettlementDetails(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getSettlementDetails(id: any) {
    this.api.get("Settlement/Get/"+id).subscribe((data: any) => {
      this.set = data.data; 
      this.trans = data.payments;
      ;
      this.use.showMsg('success', 'Request fetched', 'Settlement Details fetched successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Request Failed', 'Unable to fetch settlement Details');
    });
  }
}




