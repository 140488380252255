import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subcribers',
  templateUrl: './subcribers.component.html',
  styleUrls: ['./subcribers.component.sass']
})
export class SubcribersComponent implements OnInit {
  subcribers = []; filtered = []; id: number; exp: any[]; response:any; search = ''; report: boolean = false;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }

  constructor(private api: ManagerService, private notify: NotificationService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
    })
    this.getAllSubcribers();
  }

  getAllSubcribers(page=1,limit=12,search='') {
    this.api.get(`Subscriber/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.response = data; 
      this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.notify.showMsg('error', 'Fetching Fail', 'Unable to Fetch Subscribers List');
    });
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Subscribers Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Subscribers Export Fail', 'There is no Subscribers Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    return this.subcribers.filter(a => a.firstname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.lastname.indexOf(searchString) !== -1 || a.message.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.date.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.lastseen.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
