import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-setup-team',
  templateUrl: './setup-team.component.html',
  styleUrls: ['./setup-team.component.sass']
})
export class SetupTeamComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; 
  team: any; teams: any[] = []; merchants: any[]=[];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;user: any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }

  constructor(private route: ActivatedRoute,private api: ApiService, private use: NavComponent, private exp: ExportService) { }

  ngOnInit() {
    this.user = this.use.active;
    this.formReset();
    // this.getAllCharge();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllTeams(page,limit,search);
    });
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?limit=1000').subscribe((data: any) => {
        this.merchants = data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error", "Fetching Fail", "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getAllTeams(page=1,limit=10,search='') {
    this.api.get(`Team/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.teams = data.data; this.response = data;
      this.use.showMsg('success', 'team List', 'Fetched team list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch teams List'); });
  }

  getMore(page,limit) {
    this.api.get('Team/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.teams = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'team List Fail', 'Unable to Fetch teams List');
    });
  }

  editTeam(data) {
    this.team = data;
    this.edit = true;
  }
  delTeam(dat) {
    this.api.delete('Team/Delete/'+dat.teamId).subscribe((data: any) => {
      this.res = data; this.getAllTeams();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Delete Fail', 'Unable to Delete Team'); 
    });
  }

  saveTeam() {
    if(this.team.id) {
      this.putTeam();
    } else {
      this.postTeam();
    }
  }

  postTeam() {
    this.api.post('Team/Post', this.team).subscribe((data: any) => {
      this.getAllTeams();
      this.formReset();
      this.use.showMsg('success', 'Team Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Team');
    });
  }

  putTeam() {
    this.team.muserid = this.use.active.id; this.team.mdate = new Date();
    this.api.put('Team/Put', this.team).subscribe((data: any) => {
      this.getAllTeams(); this.formReset();
      this.use.showMsg('success', 'Team Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this.exp.exportAsExcelFile(this.teams, 'Team');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      const mer = this.use.active.merchantid || '';
      this.team = {
        id: 0,
        name: '',
        email: '',
        code: null, 
        active: true,
        merchantid: mer,
        userid: this.use.active.id,
       
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }
}
