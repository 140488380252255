import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-branch',
  templateUrl: './setup-branch.component.html',
  styleUrls: ['./setup-branch.component.css']
})
export class SetupBranchComponent implements OnInit {
  @ViewChild('branchForm') public any: NgForm;
  branchs: any[] = []; loc: any[]; com: any[] = []; total = 0;
  brn: any; edit: boolean; add: boolean; res: any; exp: any[]; id: number;
  filtered: any[] = []; count: any[] = []; reg: any[] = [];
  private _searhcTitle: string; user: any; up: any; imp: any[] = [];

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private api: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    this.getAll();
    this.getAllBranch();
  }

  getAll() {
    this.api.getCountrys().subscribe((data: any) => {
      this.count = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
    this.api.getRegions().subscribe((data: any) => {
      this.reg = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Region List');
    });
    this.api.getLocations().subscribe((data: any) => {
      this.loc = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Location List');
    });
    this.api.getCompanys().subscribe((data: any) => {
      this.com = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Company List');
    });
  }

  getAllBranch() {
    this.api.getBranchs().subscribe((data: any) => {
      this.branchs = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch List');
    });
  }

  editBranch(data) {
    this.brn = data;
    this.edit = true; this.add = true;
  }

  delBranch(dat) {
    this.api.delBranch(dat.branchid).subscribe((data: any) => {
      this.res = data; this.branchs.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Branch');
    });
  }

  reveune(send) {
    // console.log(send);
    this.total = 0;
    send.forEach((e: any) => {
      if (e.status.toLowerCase() !== 'cancel') {
        this.total += e.charge;
      }
    });
    return this.total;
  }

  postBranch() {
    this.api.postBranch(this.brn).subscribe((data: any) => {
      this.res = data; this.getAllBranch();
      this.formReset(); this.add = false;
      this._use.showMsg('success', 'Branch Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Branch');
    });
  }

  putBranch() {
    this.brn.muserid = this._use.active.id; this.brn.mdate = new Date();
    this.api.putBranch(this.brn.branchid, this.brn).subscribe((data: any) => {
      this.res = data; this.getAllBranch();
      this.formReset(); this.add = false;
      this._use.showMsg('success', 'Branch Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadBranch() {
    this.up.branches = this._ext.import;
    this.api.uploadBranch(this.up).subscribe((data: any) => {
      ; this.getAllBranch(); this.formReset(this.any);
      document.getElementById('upload').click();
      this._use.showMsg('success', data.status, data.message);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Uploading Fail', 'Unable to Upload Data');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.branchs.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.brn = { branchid: 0, companyid: this._use.active.companyid, code: null,
        name: '', countryid: '', regionid: '', locationid: '', sender: '',
        expirydate: new Date(), sessiondate: new Date(), balance: 0, send: 0,
        postal: '', mobile: '', address: '', status: 'Active',
        userid: this._use.active.id, date: this._use.date,
        muserid: null, mdate: null };
      this.up = { branchid: this._use.active.branchid, branches: [],
        userid: this._use.active.id, date: this._use.date };
    }
    this.add = false; this.edit = null;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'branch');
      this._use.showMsg('success', 'Branch Exported', 'Check Downloaded Excel File For Details');
    } else {
      this._use.showMsg('error', 'Branch Export Fail', 'There is no Branch Data to Export');
    }
  }

  read(event) {
    this._ext.readExcel(event.target.files[0]);
    setTimeout(function () { this.getData(); }, 5000);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }
  getData() {
    this.imp = this._ext.import;
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.company.name, BRANCH_NAME: e.name,
        MOBILE: e.mobile, POSTAL: e.postal,
        COUNTRY: e.country.name, REGION: e.region.name, LOCATION: e.location.name,
        SESSION: e.sessiondate, SENDERID: e.sender, STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.add = true; this.edit = false;
  }
  back() {
    this.add = false; this.formReset();
  }

}
