import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.sass']
})
export class PlanDetailsComponent implements OnInit {

  
  set: any; id: string; rpt: any;  trans: any[] = []; response: any; 

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }

  constructor(
    private api: ApiService, 
    private router: ActivatedRoute,
    private use: NavComponent,
    ) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getPlanDetails(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getPlanDetails(id: any) {
    this.api.get("Plan/Get/"+id).subscribe((data: any) => {
      this.set = data.data; 
      this.trans = data.payments;
      console.log(data);
      this.use.showMsg('success', 'Request fetched', 'Plan Details fetched successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Request Failed', 'Unable to fetch Plan Details');
    });
  }
}




