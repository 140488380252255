import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgForm, NgModel } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessagingModule, AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// ng select
import { NgSelectModule } from '@ng-select/ng-select';
// import { QrCodeModule } from 'ng-qrcode';

import { LoaderComponent } from './traits/loader/loader.component';
import { LoginComponent } from './views/login/login.component';
import { LockComponent } from './views/login/lock/lock.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';
import { NavComponent } from './traits/nav/nav.component';
import { FootComponent } from './traits/foot/foot.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { SubcribersComponent } from './views/manager/subcribers/subcribers.component';
import { UtilityComponent } from './views/manager/utility/utility.component';
import { SettlementComponent } from './views/manager/settlement/settlement.component';

import { SigninService } from './services/signin.service';
import { SetupService } from './services/setup.service';
import { ManagerService } from './services/manager.service';
import { AuthGuard } from './guard/auth.guard';
import { ExitpageGuard } from './guard/exitpage.guard';
import { FirebaseService } from './services/firebase.service';
import { NotificationService } from './services/notification.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptors';


import * as $ from 'jquery';
import { TopupComponent } from './views/manager/topup/topup.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { TopUpsComponent } from './views/manager/utility/top-ups/top-ups.component';
import { LogsComponent } from './views/manager/utility/logs/logs.component';

import { CustomersComponent } from './views/customers/customers.component';
import { ViewCustomerComponent } from './views/customers/view-customer/view-customer.component';
import { ApiService } from './services/api.service';
import { UssdComponent } from './views/ussd/ussd.component';
// import { RefundComponent } from './views/payments/refund/refund.component';
import { WalletComponent } from './views/payments/wallet/wallet.component';
import { SmsComponent } from './views/sms/sms.component';
import { SmsLogsComponent } from './views/sms/sms-logs/sms-logs.component';
import { DisputeComponent } from './views/payments/dispute/dispute.component';
import { SessionComponent } from './views/ussd/session/session.component';
import { UssdPackagesComponent } from './views/ussd/ussd-packages/ussd-packages.component';
import { BillingsComponent } from './views/ussd/billings/billings.component';
import { PayoutComponent } from './views/payments/payout/payout.component';
import { CollectionComponent } from './views/payments/collection/collection.component';
import { BulkpayComponent } from './views/payments/bulkpay/bulkpay.component';
import { ApiKeyComponent } from './views/api-key/api-key.component';
import { BillsComponent } from './views/bills/bills.component';
import { CreateBillComponent } from './views/bills/create-bill/create-bill.component';
import { UploadBillComponent } from './views/bills/upload-bill/upload-bill.component';
import { PaginationComponent } from './traits/pagination/pagination.component';
import { CreateInvoiceComponent } from './views/commerce/invoices/create-invoice/create-invoice.component';
import { SetupPaymentsComponent } from './views/setup/setup-payments/setup-payments.component';
import { PaymentComponent } from './views/payment/payment.component';
import { PaymentsComponent } from './views/manager/payments/payments.component';
import { RecurringModule } from './views/recurring/recurring.module';
import { SetupCountryComponent } from './views/setup/setup-country/setup-country.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { CommerceModule } from './views/commerce/commerce.module';
import { SetupApiComponent } from './views/setup/setup-api/setup-api.component';
import { CheckoutComponent } from './views/manager/checkout/checkout.component';
import { ViewSettlementComponent } from './views/manager/settlement/view-settlement/view-settlement.component';
import { RefundModule } from './views/payments/refund/refund.module';
import { ViewCollectionComponent } from './views/payments/collection/view-collection/view-collection.component';
import { UserIdleModule } from 'angular-user-idle';
import { SetupRegionComponent } from './views/setup/setup-region/setup-region.component';
import { SetupIndustryComponent } from './views/setup/setup-industry/setup-industry.component';
import { SetupCategoryComponent } from './views/setup/setup-category/setup-category.component';
import { SetupRegistrationComponent } from './views/setup/setup-registration/setup-registration.component';
import { SetupRegistrationTypeComponent } from './views/setup/setup-registration-type/setup-registration-type.component';
import { SetupPartnersComponent } from './views/setup/setup-partners/setup-partners.component';
import { NgxLanguageSelectorModule } from 'ngx-language-selector';
import { SetupTeamComponent } from './views/setup/setup-team/setup-team.component';
import { ComplianceModule } from './views/compliance/compliance.module';
import { SetupBankComponent } from './views/setup/setup-bank/setup-bank.component';
import { SetupAccountComponent } from './views/setup/setup-account/setup-account.component';
// import { DeviceDetectorService } from 'ngx-device-detector';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('fsp'));
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    LockComponent,
    ForgetPasswordComponent,
    NavComponent,
    FootComponent,
    PaginationComponent,
    DashboardComponent,
    SetupCompanyComponent,
    SetupBranchComponent,
    SetupSequenceComponent,
    SetupUserComponent, 
    SetupSmslogsComponent,
    SetupSmsComponent,
    NotfoundComponent,
    SetupLocationComponent,
    SetupSessionComponent,
    AlertsComponent,
    SetupSmsComponent,
    SetupSmslogsComponent,
    SubcribersComponent,
    UtilityComponent,
    UssdComponent,
    SettlementComponent,
    PaymentComponent,
    TopupComponent,
    SetupChargeComponent,
    TopUpsComponent,
    LogsComponent,
    CustomersComponent,
    ViewCustomerComponent,
    // RefundComponent,
    WalletComponent,
    SmsComponent,
    SmsLogsComponent,
    DisputeComponent,
    SessionComponent,
    UssdPackagesComponent,
    BillingsComponent,
    PayoutComponent,
    CollectionComponent,
    BulkpayComponent,
    ApiKeyComponent,
    BillsComponent,
    CreateBillComponent,
    UploadBillComponent,
    CreateInvoiceComponent,
    SetupPaymentsComponent,
    PaymentsComponent,
    SetupCountryComponent,
    SetupApiComponent,
    CheckoutComponent,
    ViewSettlementComponent,
    ViewCollectionComponent,
    SetupRegionComponent,
    SetupIndustryComponent,
    SetupCategoryComponent,
    SetupRegistrationComponent,
    SetupRegistrationTypeComponent,
    SetupPartnersComponent,
    SetupTeamComponent,
    SetupBankComponent,
    SetupAccountComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgSelectModule,
    RecurringModule,
    ComplianceModule,
    RefundModule,
    CommerceModule,
    // QrCodeModule,
    NgxLanguageSelectorModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule, AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      progressBar: true
    }),
    UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 600 }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [ SigninService, ApiService, SetupService, ManagerService, JwtHelperService,
    AuthGuard, ExitpageGuard, NotificationService, FirebaseService,
    AngularFireDatabase, AngularFireMessaging, AngularFirestore,
    // { provide: DeviceDetectorService, useClass: UniversalDeviceDetectorService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ErrorsHandler },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
