import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
declare var $: any;
@Component({
  selector: "app-ussd-packages",
  templateUrl: "./ussd-packages.component.html",
  styleUrls: ["./ussd-packages.component.sass"],
})
export class UssdPackagesComponent implements OnInit {
  @ViewChild('companyForm') public any: NgForm; id: number;
  edit: boolean; exp: any; com: any; res: any; imp: any; company: any[] = [];
  merchants: any[] = []; rpt: any;  response:any; search = ''; list: boolean;
  countries: any[] = [];
  // tslint:disable-next-line:variable-name
  private _searchTitle: string; filtered: any[] = [];

  get searchTitle(): string {
    return this._searchTitle;
  }
  set searchTitle(value: string) {
    this._searchTitle = value;
    this.search = this._searchTitle;
  }

  constructor(private api: SetupService, private route: ActivatedRoute, private _ext: ExportService, private use: NavComponent, private _use: ApiService, private set: SetupService) { }

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllPackages(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null }
    this.formReset();
    this.getAllCountries();
    this.getMerchants();
  }

  getMerchants() {
    this._use.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getAllCountries() {
    this._use.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  getAllPackages(page=1,limit=12,search='') {
    this._use.get(`UssdPackage/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.response = data; this.filtered = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Ussd App List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  editUssd(data) {
    this.com = data;
    this.edit = true; // this.list = true;
  }

  
  editCom(data) {
    this.com = data;
    this.edit = true; // this.list = true;
  }

  delUssd(data) {
    this._use.delete("UssdPackage/DeleteUssdPackage/" + data.id).subscribe((data: any) => {
      this.getAllPackages();
      this.use.showMsg('success', 'Package Deleted', 'Package Deleted Successfully');
      document.getElementById('regis').click();
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Package List"
        );
      }
    );
  }

  fileUpload(event) {
    this.api.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.com.logo = data; ;
    });
  }

  searchResult(searchString: string) {
    return this.company.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.indexOf(searchString) !== -1 || a.sender.indexOf(searchString) !== -1);
  }

  postUssd() {
    this._use.post('UssdPackage/Post', this.com).subscribe((data: any) => {
      this.res = data; 
      this.getAllPackages();
      this.formReset(); 
      document.getElementById('regis').click();
      this.use.showMsg('success', 'Ussd App Added', 'New Ussd Package Added Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Ussd');
    });
  }

  putUssd() {
    this.com.muserid = this.use.active.id; this.com.mdate = new Date();
    this._use.put('UssdPackage/Put/'+this.com.id, this.com).subscribe((data: any) => {
      this.res = data; this.getAllPackages(); this.edit = false;
      this.formReset(this.any); this.list = false;
      document.getElementById('regis').click();
      this.use.showMsg('success', 'Ussd Package Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadUssd() {
    this._use.post('Ussd/Post', this._ext.import).subscribe((data: any) => {
      this.res = data; this.getAllPackages();
      this.formReset(); document.getElementById('upload').click();
      this.use.showMsg('success', 'Ussd Package Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Ussd Package');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.com = { 
        merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid,
        name: '', 
        length: '', 
        description: '', 
        countryid: null,
        userid: this.use.active.id, 
        date: new Date(), 
        muserid: null, 
        mdate: new Date()
      };
    }
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'Ussd');
      this.use.showMsg('error', 'Ussd App Export Fail', 'There is no Ussd App Data to Export');
    } else {
      this.use.showMsg('error', 'Ussd App Export Fail', 'There is no Ussd App Data to Export');
    }
  }

  read(event) {
    this.imp = this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.name, EXPIRY: e.expiry,
        STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.edit = false;
  }
  
}

