import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-setup-bank',
  templateUrl: './setup-bank.component.html',
  styleUrls: ['./setup-bank.component.sass']
})

export class SetupBankComponent implements OnInit {
  @ViewChild('bankForm') public any: NgForm;
  edit: boolean; list: boolean; 
  bank: any; banks: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;user: any;
  countries: any[] = [];

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }

  constructor(private route: ActivatedRoute,private api: ApiService, private _use: NavComponent, private _exp: ExportService) { }


  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    // this.getAllCharge();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 10;
      const search = data.search ? data.search : '';
      // 
      this.getAllBanks(page,limit,search);
    });
    this.getAllCountries();
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  getAllBanks(page=1,limit=10,search='') {
    this.api.get(`Bank/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.banks = data.data; this.response = data;
      this._use.showMsg('success', 'Bank List', 'Fetched Bank list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Bank List'); });
  }

  getMore(page,limit) {
    this.api.get('Bank/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.banks = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Bank List Fail', 'Unable to Fetch Banks List');
    });
  }

  editBank(data) {
    this.bank = data;
    this.edit = true;
  }

  delBank(dat) {
    this.api.delete('Bank/Delete/'+dat.id).subscribe((data: any) => {
      this.res = data; this.getAllBanks();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Bank'); 
    });
  }

  saveBank() {
    if(this.bank.id) {
      this.putBank();
    } else {
      this.postBank();
    }
  }

  postBank() {
    this.api.post('Bank/Post', this.bank).subscribe((data: any) => {
      this.getAllBanks();
      this.formReset();
      this._use.showMsg('success', 'Bank Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Bank');
    });
  }

  putBank() {
    this.bank.muserid = this._use.active.id; this.bank.mdate = new Date();
    this.api.put('Bank/Put/'+this.bank.id, this.bank).subscribe((data: any) => {
      this.getAllBanks(); this.formReset();
      this._use.showMsg('success', 'Bank Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.banks, 'banks');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.bank = {
        id: 0,
        code: null,
        name: '',
        branch_name: '',  
        branch_code: null, 
        active: true,
        countryid: null
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }


}
