import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-setup-company',
  templateUrl: './setup-company.component.html',
  styleUrls: ['./setup-company.component.css']
})
export class SetupCompanyComponent implements OnInit {
  @ViewChild('companyForm') public any: NgForm; id: number;
  edit: boolean; exp: any; com: any; res: any; imp: any; company: any[] = []; 
  industries: any[] = []; categories: any[] = []; businesstypes: any[] = []; registerations: any[] = [];
  response:any; search = ''; list: boolean;
  private _searchTitle: string; filtered: any[] = []; 
  providers:any[] = ["EMERGENT","STANBIC","CROSS-SWITCH","PAYSTACK","FLUTTERWAVE","PRYMO","DEYWURO","ROUTEMOBILE"];
  countries: any[] = []; regions: any[];

  get searchTitle(): string {
    return this._searchTitle;
  }
  
  set searchTitle(value: string) {
    this._searchTitle = value;
    this.search = this._searchTitle;
  }
  // tslint:disable-next-line:variable-name
  constructor(private api: ApiService, private _ext: ExportService, private _use: NavComponent, private route: ActivatedRoute,) { }

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 6;
      const search = data.search ? data.search : '';
      this.getAllCompany(page,limit,search);
    });
    this.formReset();
    this.imp = this._ext.import;
    this.getAllCountries();
    this.getAllRegions()
    this.getSetup();
  }

  getAllCompany(page=1,limit=6,search='') {
    this.api.get(`Merchant?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.company = data; 
        this.response = data;
      }, (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List" );
      });
  }

  getSetup() {
    this.api.get('Industry/getall?limit=100').subscribe((data: any) => {
      this.industries = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg("error", "Fetching Fail", "Unable to Fetch Industries List" );
    });
    this.api.get('category/getall?limit=100').subscribe((data: any) => {
      this.categories = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg("error", "Fetching Fail", "Unable to Fetch Industries List" );
    });
    this.api.get('registrationtype/getall?limit=100').subscribe((data: any) => {
      this.businesstypes = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg("error", "Fetching Fail", "Unable to Fetch Industries List" );
    });
    this.api.get('registration/getall?limit=100').subscribe((data: any) => {
      this.registerations = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg("error", "Fetching Fail", "Unable to Fetch Industries List" );
    });
  }

  getSearch() {
    this.api.updateUrl(1,9,this.search);
  }

  editCom(data) {
    this.com = data;
    this.edit = true; // this.list = true;
  }
  viewCom(data){
    this.com = data;
    this.edit = false;
  }

  delCom(dat) {
    this.api.delete('Merchant/'+dat.merchantid).subscribe((data: any) => {
      this.res = data; this.company.slice(dat);
    }, (err: HttpErrorResponse) => { 
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Merchant'); 
    });
  }
  

  fileUpload(event) {
    this.api.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.com.logo = data; ;
    });
  }

  searchResult(searchString: string) {
    return this.company.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.indexOf(searchString) !== -1 || a.sender.indexOf(searchString) !== -1);
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  getAllRegions() {
    this.api.get('Region/GetAll').subscribe((data: any) => {
      this.regions = data.data; 
      ;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Region List Fail', 'Unable to Fetch Region List'); });
  }

  saveMerchant() {
    // console.log('processing')
    if(this.com.merchantid > 0) {
      // console.log('updating')
      this.putMerchant()
    } else {
      // console.log('creating')
      this.postMerchant();
    }
  }

  postMerchant() {
    this.api.post('Merchant/', this.com).subscribe((data: any) => {
      this.getAllCompany(); this.formReset(this.any); this.list = false;
      document.getElementById('regis').click();
      this._use.showMsg('success', 'Merchant Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
    });
  }

  putMerchant() {
    this.com.muserid = this._use.active.id; this.com.mdate = new Date();
    this.api.put('Merchant/'+this.com.merchantid, this.com).subscribe((data: any) => {
      this.res = data; this.getAllCompany();
      this.formReset(this.any); this.list = false; 
      document.getElementById('regis').click();
      this._use.showMsg('success', 'Merchant Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadMerchant() {
    this.api.post('Merchant/batch', this._ext.import).subscribe((data: any) => {
      this.res = data; this.getAllCompany();
      this.formReset(); document.getElementById('upload').click();
      this._use.showMsg('success', 'Merchant Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.com = { merchantid: 0, code: '', type: null, category: null, slug: '',  apikey: '', charge: 0,
        name: '', email: '', mobile: '', logo: 'logo.png', status: 'Active',
        account: '', bank: '', bankname: '', balance: 0, smsbalance: 0,
        allowsms: false, active: true, live: true, master: true, external_ova: false, dedicated_setup: false, address: '', 
        partner_code: '', sender: '', send: 0, categoryid:null, industryid:null, businesstypeid:null,
        sms_provider: null, whitelisted_ip: '', ip_restricted: false, callbackurl: '',part:false, partnerid:null, tin: null,
        allow_collection: true, collection_provider: null, countryid:null, regionid:null,
        allow_card: true, card_provider: null, allow_cashout: true, cashout_provider: null,
        allow_airtime: true, airtime_provider: null, allow_billers: true, billers_provider: null,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null,
      };
    }
    this.edit = false;
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'merchant');
      this._use.showMsg('error', 'Merchant Export Fail', 'There is no Merchant Data to Export');
    } else {
      this._use.showMsg('error', 'Merchant Export Fail', 'There is no Merchant Data to Export');
    }
  }

  read(event) {
    this.imp = this._ext.readExcel(event.target.files[0]);
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.name, EXPIRY: e.expiry,
        STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.edit = false;
  }

   // putMerchant() {
  //   this.com.muserid = this._use.active.id; this.com.mdate = new Date();
  //   this.api.putCompany(this.com.merchantid, this.com).subscribe((data: any) => {
  //     this.res = data; this.getAllCompany(); this.edit = false;
  //     this.formReset(); document.getElementById('regis').click();
  //     this._use.showMsg('success', 'Merchant Updated', 'Details Updated Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
  //   });
  // }

   // postMerchant() {
  //   this.api.postCompany(this.com).subscribe((data: any) => {
  //     this.res = data; this.getAllCompany();
  //     this.formReset(); document.getElementById('regis').click();
  //     this._use.showMsg('success', 'Merchant Added', 'New Merchant Added Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
  //   });
  // }

    // delete(val) {
  //   this.api.delCompany(val.merchantid).subscribe(data=> {
  //     this.formReset(); document.getElementById('regis').click();
  //     this._use.showMsg('success', 'Company Deleted', 'Merchant Deleted Successfully');
  //   },(err:HttpErrorResponse)=> {
  //     this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Merchant');
  //   })
  // }

   // delCom(dat) {
  //   this.api.delCompany(dat.companyid).subscribe((data: any) => {
  //     this.res = data; this.company.slice(dat);
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Merchant'); });
  // }

    // getAllCompany(page=1,limit=12,search='') {
  //   this.api.getCompanys().subscribe((data: any) => {
  //     this.use.get(`merchant?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
  //     this.company = data; this.filtered = data;
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Details'); });
  // }
  
}
