import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-smslogs',
  templateUrl: './setup-smslogs.component.html',
  styleUrls: ['./setup-smslogs.component.css']
})
export class SetupSmslogsComponent implements OnInit {
  sms: any; smslog: any[] = []; res: any; filtered: any[] = [];
  private _searhcTitle: string; response:any; search = '';

  get searchTitle(): string {
    return this._searhcTitle;
  }
 

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private _use: NavComponent,  private route: ActivatedRoute, private _exp: ExportService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      
    })
    this.formReset();
    this.getAllSmsLog();
  }

  getAllSmsLog(page=1,limit=12,search='') {
      this.api.get(`Sms/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.smslog = data.data; this.response = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  getSearch() {
    this.api.updateUrl(1,8,this.search);
  }

  serachResult(searchString: string) {
    return this.smslog.filter(a => a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.tcode.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.response.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.message.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  retry(id) {
    this.api.get('Sms/Retry/'+id).subscribe((data: any) => {
      this.res = data; this.getAllSmsLog();
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  searchData(data) {
    ;
  }

  Export() {
    this._exp.exportAsExcelFile(this.smslog, 'Smslog');
  }


  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.sms = { id: '', muserid: this._use.active.Id, mdate: new Date() };
    }
  }

}
