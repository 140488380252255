import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireFunctions } from '@angular/fire/functions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Import firebase to fix temporary bug in AngularFire
import * as app from 'firebase';
import { SigninService } from './signin.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token: any; user: any;
  constructor(public toastr: ToastrService, private af: AngularFireMessaging, private use: SigninService) {
    this.user = this.use.userDetails();
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  notify(message) {
    this.toastr.error(message, 'Error Message');
  }

  success(data) {
    if (data.message) {
      this.toastr.success(data.message, data.title || 'Success');
    }
  }

  async makeToast(data) {
    const toast = await this.toastr.info(data.message, data.title);
    // {
    //   message,
    //   duration: 5000,
    //   position: 'top',
    //   showCloseButton: true,
    //   closeButtonText: 'dismiss'
    // });
    // toast.onShown();
  }

  getPermission(): Observable<any> {
    return this.af.requestToken.pipe(
      tap(token => (this.token = token))
    );
  }

  showMessages(): Observable<any> {
    return this.af.messages.pipe(
      tap(msg => {
        const body: any = (msg as any).notification; // .body;
        this.makeToast(body);
      })
    );
  }

}
