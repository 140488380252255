import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { first } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.sass']
})
export class CustomersComponent implements OnInit {
  customers: any[] = []; filtered: any[] = []; st: any; id = 0; exp: any; upload:any[]=[];
  payment: any[] = [];total = 0; merchants: any[] = []; rpt: any; balance = 0; view: boolean = false; tran: any; 
  response:any; search = ''; res: any; edit: boolean; report: boolean = false;
  saving: boolean = false; cust: any; topup: any; type: any[] = ["RECEIVE", "SEND"]; newCustomer: any;
  ref: any;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  constructor(private api: ApiService, public use: NavComponent, private route: ActivatedRoute, public ext: ExportService, private set: SetupService,) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllCustomer(page,limit,search);
    })
    this.getMerchants();
    this.init();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null }
  }

  getTotal(trans) {
    
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  generate() {
    this.api.post('customer/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
    }, (err: HttpErrorResponse) => {
      this.api.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer List');
    });
  }

  getMerchants() {
    this.api.get('merchant').subscribe((data: any) => {
        this.merchants = data;
        
      },(err: HttpErrorResponse) => {
        this.api.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,8,this.search);
  }

  getAllCustomer(page=1,limit=8,search='') {
    this.api.get(`Customer/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.customers = data.data; this.filtered = data.data; this.response = data;
      console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer List');
    });
  }

  addCustomer() {
    this.saving = true;
    this.api.post('Customer/Post', this.cust).subscribe((data: any) => {
      this.getAllCustomer();
      document.getElementById('close').click();
      this.api.showMsg('success', 'Customer Add', 'Customer Created Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.api.showMsg('error', 'Customer Creation Failed', err.message || 'Unable to Create Customer');
    });
  }

  editCust(data) {
    this.cust = data;
    this.edit = true;
  }

  delCustomer(data) {
    this.api.delete("Customer/DeleteCustomer/" + data.id).subscribe((data: any) => {
      this.getAllCustomer();
      this.api.showMsg('success', 'Customer Deleted', 'Customer Deleted Successfully');
    },
      (err: HttpErrorResponse) => {
        this.api.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Customer List"
        );
      }
    );
  }

  putCustomer() {
    this.cust.userid = this.use.active.id; this.cust.mdate = new Date();
    this.api.put('Customer/Put/' + this.cust.customerid, this.cust).subscribe((data: any) => {
      this.res = data; this.getAllCustomer();
      document.getElementById('close').click();
      this.api.showMsg('success', 'Customer Updated', 'Customer Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.api.showMsg('error', 'Update Fail', 'Unable to Update Customer');
    });
  }

  searchResult(searchString: string) {
    return this.customers.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'customers');
      this.api.showMsg('success', 'Customers Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.api.showMsg('error', 'Customers Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, MOBILE: e.mobile, EMAIL: e.email, OTHER: e.other,
        ACTIVE: e.active, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  init() {
    this.cust = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, code: null, name: '', email: '', mobile: '',  userid: this.use.active.id, date: new Date(), mdate: new Date()};
  }
  
}
