
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-registration-type',
  templateUrl: './setup-registration-type.component.html',
  styleUrls: ['./setup-registration-type.component.sass']
})
export class SetupRegistrationTypeComponent implements OnInit {
  @ViewChild('RegTypesForm') public any: NgForm;
  edit: boolean; list: boolean; 
  regType: any; regTypes: any[];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportService) { }

  ngOnInit() {
    this.formReset();
    this.getAllRegType();
  }

  getAllRegType() {
    this.api.get('RegistrationType/GetAll').subscribe((data: any) => {
      this.regTypes = data.data; this.response = data;
      this.use.showMsg('success', 'Registrstion Types List', 'Fetched Registration list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Registration Type List Fail', 'Unable to Fetch Registration Types List'); });
  }

  getMore(page,limit) {
    this.api.get('RegistrationType/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.regTypes = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Registration Types List Fail', 'Unable to Fetch Registration Types List');
    });
  }

  editRegTypes(data) {
    this.regType = data;
    this.edit = true;
  }

  delRegTypes(dat) {
    this.api.delete('RegistrationType/Delete/'+dat.id).subscribe((data: any) => {
      this.res = data; this.getAllRegType();
    }, (err: HttpErrorResponse) => { console.log(err); });
  }

  postRegType() {
    this.api.post('RegistrationType/Post',this.regType).subscribe((data: any) => {
      this.getAllRegType(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Registration type Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Registration Types');
    });
  }

  putRegTypes() {
    this.regType.muserid = this.use.active.id; this.regType.mdate = new Date();
    this.api.put('RegistrationType/Put/'+this.regType.id, this.regType).subscribe((data: any) => {
      this.getAllRegType(); this.edit = false;
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'RegistrationType Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.regType = {
        regTypeid: 0, code: null, name: '',
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  Export() {
    this._exp.exportAsExcelFile(this.regTypes, 'Registration Types');
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
