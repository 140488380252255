import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.sass']
})
export class SubscribersComponent implements OnInit {

  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = [ "RECEIVE", "SEND" ]; view: boolean = false; report: boolean = false; tran: any;  response:any; search = '';
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private ext: ExportService, private use: NavComponent,  private route: ActivatedRoute) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
     this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllPayment(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null, code: null }
    this.getMerchants();
  }

  getMerchants() {
    this.api.get('merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllPayment(page=1,limit=12,search='') {
      this.api.get(`Subscriber/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.payment = data.data; this.response = data; 
      // this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Subscribers List');
    });
  }

  deleteSubscriber(val) {
    this.api.delete("getAllPayment/" + val.code).subscribe(
      (data: any) => {
        this.getAllPayment();
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to delete Subscriber"
        );
      }
    );
  }


  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    if(this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('subscribers/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; 
      // this.getTotal(data);
      setTimeout(() => {
        $('.footable').footable();
      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Subscribers List');
    });
  }

  // getTotal(trans) {
  
  //   this.total = 0;
  //   trans.forEach((e: any) => {
  //     if (e.code === 1) {
  //       this.total += e.amount;
  //     }
  //   });
  //   return this.total;
  // }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Subscribers Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Subscribers Export Fail', 'There is no Subscribers Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }


}
