import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.sass']
})
export class ApiKeyComponent implements OnInit {

  apikey: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = ["RECEIVE", "SEND"]; view: boolean = false; report: boolean = false; tran: any;
  topup: any; saving: boolean = false; key: any;
  options: any[] = ["MOBILE MONEY", "CREDIT CARD"];
  response:any; search = ''; ref: any;
  countries: any[] = [];
  res: any; edit: boolean;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute,) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      this.getAllKeys(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null }
    this.formReset();
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllKeys(page=1,limit=12,search='') {
    this.api.get(`ApiKey/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.response = data; 
      this.apikey = data.data; 
      console.log(data)
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Api Key List');
    });
  }


  delKey(data) {
    this.api.delete("ApiKey/DeleteApiKey/" + data.apikeyid).subscribe((data: any) => {
      this.getAllKeys();
      this.use.showMsg('success', 'Key Deleted', 'Api-Key Deleted Successfully');
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Api-Key List"
        );
      }
    );
  }

  editKey(data) {
    this.key = data;
    this.edit = true;
  }

  saveKey() {
    this.saving = true;
    this.key.service = this.key.services.join(",");
    this.api.post('ApiKey/Post', this.key).subscribe((data: any) => {
      this.getAllKeys();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Api Key Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Api Key Generation Failed', err.message || 'Unable to Generate Api Key');
    });
  }


  postKey() {
    this.saving = true;
    this.key.service = this.key.services.join(",");
    this.api.post('ApiKey/Post', this.key).subscribe((data: any) => {
      this.getAllKeys();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Request Sent', 'Api Key Request send Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Api Key Generation Failed', err.message || 'Unable to Generate Api Key');
    });
  }


  putKey() {
    this.key.muserid = this.use.active.id; this.key.mdate = new Date();
    this.api.put('ApiKey/Put/' + this.key.apikeyid, this.key).subscribe((data: any) => {
      this.res = data; this.getAllKeys();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Wallet Updated', 'Wallet Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Update Fail', 'Unable to Update Wallet');
    });
  }

  formReset() {
    this.key = { 
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, 
      name: "", 
      reference: "",
      services: null,
      service:"",
      active: true,
      master: true,
      whitelisted_ip : '',
      userid: this.use.active.id, 
      date: new Date(), 
      mdate: new Date() };
  }


  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('ApiKey/Report', this.rpt).subscribe((data: any) => {
      this.apikey = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Api Key List');
    });
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Api Keys Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Api Keys Export Fail', 'There is no Api Key Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    return this.apikey.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1) !== -1);
  }

}
