import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.sass']
})
export class ViewCustomerComponent implements OnInit {
  cust: any; accnt: any; id: string; rpt: any; statements: any;
  add: boolean; accounts: any[] = []; trans: any[] = []; response: any; search: string = '';

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }

  constructor(
    private api: ApiService, 
    private _use: NavComponent, 
    private _api: ManagerService, 
    private rout: Router, 
    private router: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getAllCustomers(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getAllCustomers(id: any) {
    this.api.get("Customer/Get/"+id).subscribe((data: any) => {
      this.cust = data.data; this.accounts = data.accounts;
      this.trans = data.payments;
      this._use.showMsg('success', 'Request fetched', 'Customer Details Fetched');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Request Failed', 'Unable to fetch Customer Details');
    });
  }


  getACcountReport() {
    this.api.post("accounts/statement/",this.rpt).subscribe((data: any) => {
      this.statements = data;
      ;
    });
  }
  

}




