import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setup-api',
  templateUrl: './setup-api.component.html',
  styleUrls: ['./setup-api.component.sass']
})
export class SetupApiComponent implements OnInit {
  @ViewChild('smsapiForm') public any: NgForm;
  edit: boolean; list: boolean; api: any; smsapi: any[]; merchants: any[] = []; res: any; response:any;
  constructor(private route: ActivatedRoute, public _use: NavComponent, private use : ApiService, private _exp: ExportService) { }

  ngOnInit() {
    this.formReset();
    // this.getAllSmsApi();
    this.getMerchant();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllSmsApi(page,limit,search);
    });
  }

  getAllSmsApi(page=1,limit=30,search='') {
    this.use.get(`SmsApi/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.smsapi = data.data; this.response = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  getMerchant() {
    this.use.get('Merchant').subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  editApi(data) {
    this.api = this.smsapi.find(s => s.smsapiid === data);
    // this.api = data;
    this.edit = true; this.list = true;
  }
  delApi(dat) {
    this.use.delete('SmsApi/Delete/' +dat.smsapiid).subscribe((data: any) => {
      this.res = data; this.smsapi.slice(dat); this.getAllSmsApi();
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postSmsApi() {
    this.use.post('SmsApi/Post', this.api).subscribe((data: any) => {
      this.res = data;  this.getAllSmsApi();
      this.formReset(); this.list = false;
      this._use.showMsg('success', 'SmsApi Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New SmsApi');
    });
  }

  putSmsApi() {
    this.api.muserid = this._use.active.id; this.api.mdate = new Date();
    this.use.put('SmsApi/Put/' + this.api.smsapiid, this.api).subscribe((data: any) => {
      this.res = data; this.getAllSmsApi();
      this.formReset(); this.list = false;
      this._use.showMsg('success', 'SmsApi Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.smsapi, 'smsapi');
  }


  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.api = { smsapiid: 0, merchantid: '', name: '', senderid: '', url: '', callackurl: '',
      username: '', password: '', default: false, status: 'Active',
      userid: this._use.active.id, date: new Date(), muserid: null, mdate: null };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
