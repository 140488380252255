import { Component, OnInit } from '@angular/core';
import { ManagerService } from "src/app/services/manager.service";
import { SetupService } from "src/app/services/setup.service";
import { ExportService } from "src/app/services/export.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.sass']
})
export class DisputeComponent implements OnInit {
  dispute: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  id: number;
  exp: any[];
  balance = 0;
  view: boolean = false; report: boolean = false;
  tran: any;
  search = "";
  response:any;
  type: any[] = ["RECEIVE", "SEND"];
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  // set searchTitle(value: string) {
  //   this._searhcTitle = value;
  //   this.search = value;
  //   this.filtered = this.searchResult(value);
  //   this.getTotal(this.filtered);
  // }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    // private api: ManagerService,
    private set: SetupService,
    private api: ApiService,
    private route: ActivatedRoute,
    private ext: ExportService,
    private use: NavComponent
  ) {}

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.GetAllDisputes(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null, code: null }
    this.getMerchants();
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getMerchant(val) {
    this.set.getCompany(val).subscribe(
      (data: any) => {
        this.balance = data.balance;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchant Balance"
        );
      }
    );
  }

  GetAllDisputes(page=1,limit=12,search='') {
    this.api.get(`Dispute/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.dispute = data;
        this.filtered = data.data;
        // this.getTotal(data);
        setTimeout(() => {
          $(".footable").footable();
        }, 2000);
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Disputes List"
        );
      }
    );
  }

  deleteDispute(val) {
    this.api.get("Dispute/DeleteDispute/" + val.paymentid).subscribe(
      (data: any) => {
        this.GetAllDisputes();
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Disputes List"
        );
      }
    );
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  update() {
    this.api.get("Dispute/Put/" + this.rpt.merchantid).subscribe(
      (data: any) => {},
      (err: HttpErrorResponse) => {
        this.use.showMsg("error", "Fetching Fail", err.message);
      }
    );
  }

  checkDispute(val) {
    const code = val.transaction_no;
    if (!code || code == 0) {
      code == val.tranid;
    }
    this.api.get("Dispute/Confirm/" + code).subscribe(
      (data: any) => {
        this.GetAllDisputes();
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Disputes List"
        );
      }
    );
  }

  generate() {
    if (this.rpt.type == "SEND") {
      this.rpt.settled = true;
    }
    this.api.post("Dispute/Report", this.rpt).subscribe(
      (data: any) => {
        this.dispute = data;
        this.filtered = data;
        this.getMerchant(this.rpt.merchantid);
        // this.getTotal(data);
        setTimeout(() => {
          $(".footable").footable();
        }, 2000);
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Disputes List"
        );
      }
    );
  }

  // getTotal(trans) {
  
  //   this.total = 0;
  //   trans.forEach((e: any) => {
  //     if (e.code === 1) {
  //       this.total += e.amount;
  //     }
  //   });
  //   return this.total;
  // }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "items");
      this.use.showMsg(
        "success",
        "Disputes Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Disputes Export Fail",
        "There is no Disputes Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id,
        TRANID: e.tranid,
        ACCOUNT: e.account,
        TYPE: e.type,
        MOBILE: e.mobile,
        NETWORK: e.network,
        SERVICE: e.service,
        AMOUNT: e.amount,
        TRANSACTION_NO: e.transaction_no,
        TRANSACTION_REF: e.transaction_ref,
        REFERENCE: e.reference,
        RESPONSE: e.response,
        CREATEDDATE: e.date,
        UPDATED: e.date,
      };
      this.exp.push(ed);
      this.id++;
    });
    return this.exp;
  }
  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.dispute.filter(
      (a) =>
        a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
        a.mobile.indexOf(searchString) !== -1 ||
        a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !==
          -1 ||
        a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !==
          -1 ||
        a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1
    );
  }
}
