import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setup-charge',
  templateUrl: './setup-charge.component.html',
  styleUrls: ['./setup-charge.component.sass']
})
export class SetupChargeComponent implements OnInit {
@ViewChild('sessionForm') public any: NgForm;
  ses: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; res: any; list: boolean;
  charges: any[] = []; merchants: any[] = []; types:any[] = ["SEND", "RECEIVE", "CARD", "SMS"]; user: any;

  constructor(private route: ActivatedRoute, private _use: NavComponent, private api: ApiService, private _exp: ExportService) { }

  ngOnInit() {
    this.user = this._use.active;
    this.curr = new Date();
    this.formReset();
    // this.getAllCharge();
    this.getMerchant();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllCharge(page,limit,search);
    })
  }


  getAllCharge(page=1,limit=10,search='') {
    this.api.get(`Charge/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.charges = data.data; this.response = data;
      this._use.showMsg('success', 'Charge List', 'Fetched Charge list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Charge List'); });
  }

  getMerchant() {
    this.api.get('Merchant').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  editCharge(data) {
    this.ses = data;
    this.edit = true;
  }

  delCharge(dat) {
    this.api.delete('Charge/Delete/'+dat.id).subscribe((data: any) => {
      this.res = data; this.getAllCharge();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Charge'); 
    });
  }

  saveCharge() {
    if(this.ses.chargeid || this.ses.id) {
      this.putCharge();
    } else {
      this.postCharge();
    }
  }

  postCharge() {
    this.api.post('Charge/Post', this.ses).subscribe((data: any) => {
      this.getAllCharge();
      this.formReset();
      this._use.showMsg('success', 'Charge Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Charge');
    });
  }

  putCharge() {
    this.ses.muserid = this._use.active.id; this.ses.mdate = new Date();
    this.api.put('Charge/Put/'+this.ses.id, this.ses).subscribe((data: any) => {
      this.getAllCharge(); this.formReset();
      this._use.showMsg('success', 'Charge Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.charges, 'charges');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ses = {
        merchantid: (this._use.active.typ === 'Admin' || this._use.active.typ === 'Support')? null:this._use.active.merchantid,
        // chargeid: 0, 
        type: "", 
        percentage: true, 
        value: 0, 
        amount: 0, 
        minimum: 0, 
        maximum: 0,
        partner: 0, 
        charge_on_customer: false, 
        active: true,
        userid: this._use.active.id, 
        date: new Date(), 
        mdate: new Date(), 
        muserid: "",
        // merchant: ""
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }

  
}
