import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  checkouts: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any; exp: any[]; id: number;
  type: any[] = [ "RECEIVE", "SEND" ]; view: boolean = false; report: boolean = false; tran: any; response:any; search = ''; page: any;
  saving: boolean = false; isShowDiv = true;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
 
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      // 
      this.getAllCheckouts(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null, code: null }
    this.getMerchants();
  }

  getMerchants() {
    this.api.get('Merchant').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg( "error", "Fetching Fail", "Unable to Fetch Merchants List" );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllCheckouts(page=1,limit=12,search='') {
      this.api.get(`Checkout/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.checkouts = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment Checkouts List');
    });
  }


  generate() {
    this.api.post('Checkouts/Report', this.rpt).subscribe((data: any) => {
      this.checkouts = data; this.filtered = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment Checkouts List');
    });
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'items');
      this.use.showMsg('success', 'Payment Checkouts Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Payment Checkouts Export Fail', 'There is no Payment Checkouts Data to Export');
    }
  }
  Expiry() {
    
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service,
        AMOUNT: e.amount, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    // return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase() || a.mobile.indexOf(searchString) !== -1 || a.reference.indexOf(searchString) !== -1 ) !== -1);
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

}
