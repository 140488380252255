import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, EmailValidator } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SigninService } from 'src/app/services/signin.service';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.css']
})
export class SetupUserComponent implements OnInit {
  @ViewChild('userForm') public any: NgForm; sel: any;
  edit: boolean; list: boolean; usr: any; users: any[]; merchant: any[]; res: any;
  constructor(private _setup: SetupService, private api: ApiService, private _api: SigninService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllUsers();
    this.getMerchant();
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
  }

  getAllUsers() {
    this.api.get('Auth/Users').subscribe((data: any) => {
      this.users = data;
      setTimeout(() => {
        $('.footable').footable();
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'User Lists', 'Unable to Get All Users'); });
  }

  editUser(data) {
    // this.usr = this.users.find(s => s.usersid === data);
    this.usr = data;
    this.edit = true; this.list = true;
  }


  delUser(dat) {
    this.api.delete('Auth/'+ dat.id).subscribe((data: any) => {
      this.res = data; this.users.slice(dat);
    }, (err: HttpErrorResponse) => { 
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete User')
     });
  }

  getMerchant() {
    this.api.get('Merchant?limit=1000').subscribe((data: any) => {
        this.merchant = data;
      }, (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error", "Fetching Fail", "Unable to Fetch Merchants List"
        );
      }
    );
  }

  reset(dat) {
    this.api.Reset(dat.email).subscribe((data: any) => {
      this.res = data;
      this._use.showMsg('success', 'User Account Reset', 'User Shld Check Email for his new password');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Reset Fail', 'Unable to Reset User');
    });
  }

  saveUser() {
    if(this.usr.id != null){
      this.putUser();
    } else {
      this.postUser();
    }
  }

  postUser() {
    if (this.usr.password === this.usr.conpassword) {
      this.api.post('Auth/User',this.usr).subscribe((data: any) => {
        this.res = data; this.getAllUsers(); this.formReset(); this.list = false;
        this._use.showMsg('success', 'User Added', 'Added to list Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Adding Fail', 'Unable to Add New User');
      });
    } else {
      this._use.showMsg('error', 'Adding Fail', 'User password do not match');
    }
  }

  putUser() {
    this.usr.userid = this._use.active.id; this.usr.date = new Date();
    this.usr.muserid = this._use.active.id; this.usr.mdate = new Date();
    this.api.put('Auth/User/' + this.usr.id, this.usr).subscribe((data: any) => {
      this.res = data; this.getAllUsers(); this.formReset(); this.list = false;
      this._use.showMsg('success', 'User Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }


  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.edit = false;
      const mer = this._use.active.merchantid || '';
      this.usr = { 
        id: null, 
        fullname: '', 
        email: '', 
        mobile: '', 
        image:'',
        username: '', 
        password: '', 
        usertype: '', 
        merchantid: mer,
        userid: this._use.active.id, 
        date: new Date(), 
        muserid: null, 
        mdate: new Date(), 
        // isloggedin: true,
        // login: true, 
        // logout: false, 
      };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

  // getMerchant() {
  //   this._setup.getCompanys().subscribe((data: any) => {
  //     this.merchant = data;
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
  //   });
  // }

  // delUser(dat) {
  //   this._setup.delUser(dat.id).subscribe((data: any) => {
  //     this.res = data; this.getAllUsers();
  //   }, (err: HttpErrorResponse) => {
  //     this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete User'); });
  // }
}
