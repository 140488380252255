import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.sass']
})
export class UtilityComponent implements OnInit {
  utility: any[] = []; filtered: any[] = []; st: any; id = 0; exp: any; response:any; search = '';
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
 
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private ext: ExportService,  private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllUtility(page,limit,search);
    })
  }

  getAllUtility(page=1,limit=12,search='') {
    this.api.get(`Utility?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.utility = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Utility List');
    });
  }

  
  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  postUtility() {
    this.api.post('Utility', this.st).subscribe((data: any) => {
      this.getAllUtility();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Utility Send', 'Quantity Added To current Utility Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Utility Fail', 'Unable to Add New Utility');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.utility.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

}
