import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-registration',
  templateUrl: './setup-registration.component.html',
  styleUrls: ['./setup-registration.component.sass']
})
export class SetupRegistrationComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; 
  registration: any; registrations: any[]; regTypes: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportService) { }

  ngOnInit() {
    this.formReset();
    this.getAllRegistration();
    this.getRegTypes();
  }

  getRegTypes() {
    this.api.get('RegistrationType/GetAll?limit=100').subscribe((data: any) => {
        this.regTypes = data.data;  
      },(err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Registration Type List"
        );
      }
    );
  }

  getAllRegistration() {
    this.api.get('registration/GetAll').subscribe((data: any) => {
      this.registrations = data.data; this.response = data;
      this.use.showMsg('success', 'registration List', 'Fetched registration list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Registration List Fail', 'Unable to Fetch registration List'); });
  }

  getMore(page,limit) {
    this.api.get('registration/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.registrations = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Registration List Fail', 'Unable to Fetch registration List');
    });
  }

  editRegistration(data) {
    this.registration = data;
    this.edit = true;
  }

  delRegistration(dat) {
    this.api.delete('registration/Delete/'+dat.id).subscribe((data: any) => {
      this.res = data; this.registrations.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postRegistration() {
    this.api.post('registration/Post',this.registration).subscribe((data: any) => {
      this.getAllRegistration(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Registration Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Registration');
    });
  }

  putRegistration() {
    this.registration.muserid = this.use.active.id; this.registration.mdate = new Date();
    this.api.put('registration/Put/'+this.registration.id, this.registration).subscribe((data: any) => {
      this.getAllRegistration(); this.edit = false;
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Registration Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.registration = {
        registrationid: 0, name: '', code: null, registrationtypeid: null,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  Export() {
    this._exp.exportAsExcelFile(this.registrations, 'Registration');
  }

  listView() {
    this.list = true; this.edit = false;
  }

  back() {
    this.list = false;
  }

}
