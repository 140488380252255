import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { send } from 'q';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.sass']
})
export class SmsComponent implements OnInit {
  @ViewChild('alertForm') @ViewChild('singleForm') public any: NgForm;
  send: any; sing: any; date: any; save: any; edit: boolean; rpt: any;
  cust: any[] = []; get: any; res: any; merchants: any[] = [];
  constructor(private _api: SetupService, private _use: NavComponent, private api: ApiService) { }

  ngOnInit() {
    this.date = this._use.date;
    this.formReset();
    this.single()
    this.rpt = { merchantid: null, start: "", end: "", code: null };
    this.getMerchant();
  }

  getDefault() {
    this._api.getSmslogsDistinct().subscribe((data: any) => {
      this.cust = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Mobile Numbers');
    });
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  PostSingle() {
    this.api.post('Sms/Post', this.sing).subscribe((data: any) => {
      this.sing = data;
      this._use.showMsg('success', 'Sms Sent', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Sending Fail', 'Unable to Send Sms ' + err);
    });
  }

  postSms() {
    if (this.save.alertid > 0) {
      this._api.putSms(this.save.alertid, this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Updated', 'Alert Has been Updated Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Update Sms Alert');
      });
    } else {
      this._api.postSms(this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Saved', 'Sms Has been Saved Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Save Sms Alert');
      });
    }
  }

  getSms(value) {
    this._api.getSmsByType(value).subscribe((data: any) => {
      this.save = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'No Saved Alert message for ' + value);
    });
  }

  getAllC() {
    this.cust.forEach((e: any) => {
      if (e.mobile.length > 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.send = { mobile: '', message: '', option: 'All', count: 0,
        userid: this._use.active.id, date: new Date() };

      this.sing = { type: 'single', tcode: '', mobile: '', message: '', code: '',
        userid: this._use.active.id, date: new Date() };

      this.save = {
        alertid: 0, type: '', message: '', name: false, auto: true,
        userid: this._use.active.id, date: new Date()
      };
    }
  }
              
  single() {
    this.sing = { 
     merchantid: this._use.active.merchantid,
     type: '', 
     tcode: '', 
     message: '',
     mobile: '',  
     userid: this._use.active.id, 
     date: new Date(), 
     mdate: new Date()};
  }

}
