import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setup-partners',
  templateUrl: './setup-partners.component.html',
  styleUrls: ['./setup-partners.component.sass']
})
export class SetupPartnersComponent implements OnInit {
  @ViewChild('sessionForm') public any: NgForm;
  partner: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; res: any; list: boolean;
  partners: any[] = []; types:any[] = ["SEND", "RECEIVE", "CARD", "SMS"]; user: any;


  constructor(private route: ActivatedRoute, private _use: NavComponent, private api: ApiService, private _exp: ExportService) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      this.getAllPartners(page,limit,search);
    })

  }

  getAllPartners(page=1,limit=10,search='') {
    this.api.get(`Partner/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.partners = data.data; this.response = data;
      this._use.showMsg('success', 'Partner List', 'Fetched Partner list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Partner List'); });
  }

  editPartner(data) {
    this.partner = data;
    this.edit = true;
  }

  delPartner(dat) {
    this.api.delete('Partner/Delete/'+dat.chargeid).subscribe((data: any) => {
      this.res = data; this.getAllPartners();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Partner'); 
    });
  }

  savePartner() {
    if(this.partner.chargeid) {
      this.putPartner();
    } else {
      this.postPartner();
    }
  }

  postPartner() {
    this.api.post('Partner/Post', this.partner).subscribe((data: any) => {
      this.getAllPartners();
      this.formReset();
      this._use.showMsg('success', 'Partner Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Partner');
    });
  }

  putPartner() {
    this.partner.muserid = this._use.active.id; this.partner.mdate = new Date();
    this.api.put('Partner/Put', this.partner).subscribe((data: any) => {
      this.getAllPartners(); this.formReset();
      this._use.showMsg('success', 'Partner Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.partners, 'Partners');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.partner = {
        type: "", 
        code: null,
        name:'',
        email:'',
        mobile: '',
        muserid: "",
        // merchant: ""
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }


}
