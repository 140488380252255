import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommerceRoutingModule } from './commerce-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InvoicesComponent } from './invoices/invoices.component';
import { PaymentPagesComponent } from './payment-pages/payment-pages.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaymentDetailsComponent } from './payment-pages/payment-details/payment-details.component';
import { InvoiceDetailComponent } from './invoices/invoice-detail/invoice-detail.component';
@NgModule({
  declarations: [InvoicesComponent, InvoiceDetailComponent, PaymentPagesComponent, PaymentDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommerceRoutingModule,
    NgSelectModule,
  ]
})
export class CommerceModule { }
