import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-sms-logs',
  templateUrl: './sms-logs.component.html',
  styleUrls: ['./sms-logs.component.sass']
})
export class SmsLogsComponent implements OnInit {

  sms: any; smslog: any[] = []; res: any; filtered: any[] = [];
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private _setup: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllSmsLog();
  }

  getAllSmsLog() {
    this._setup.getSmslogs().subscribe((data: any) => {
      this.smslog = data.data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.smslog.filter(a => a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.tcode.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.response.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.message.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

  retry(id) {
    this._setup.postSmslog(id).subscribe((data: any) => {
      this.res = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  searchData(data) {
    ;
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.sms = { id: '', muserid: this._use.active.Id, mdate: new Date() };
    }
  }

}
