import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-region',
  templateUrl: './setup-region.component.html',
  styleUrls: ['./setup-region.component.sass']
})
export class SetupRegionComponent implements OnInit {
  @ViewChild('regionForm') public any: NgForm;
  edit: boolean; list: boolean; loc: any; location: any[]; merchants: any[] = [];
  res: any; response: any; search: string = '';  rpt: any; 
  private _searhcTitle: string; filtered: any[] = []; countries: any[] = [];

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private _setup: SetupService, private use: NavComponent, private _exp: ExportService) { }

  ngOnInit() {
    this.formReset();
    this.getAllRegion();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchants();
    }
    this.getAllCountries();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, code: 0 }
  }

  getAllRegion() {
    this.api.get('Region/GetAll').subscribe((data: any) => {
      this.location = data.data; 
      this.response = data;
      this.use.showMsg('success', 'Region List', 'Fetched Region list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Region List Fail', 'Unable to Fetch Region List'); });
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }

  getMore(page,limit) {
    this.api.get('Region/GetAll?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.location = data.data; this.response = data; ;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }


  getMerchants() {
    this.api.get('Merchant').subscribe((data: any) => {
        this.merchants = data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error", "Fetching Fail", "Unable to Fetch Merchants List"
        );
      }
    );
  }

  Export() {
    this._exp.exportAsExcelFile(this.location, 'locations');
  }

  editLoc(data) {
    this.loc = data;
    this.edit = true;
  }

  delLoc(dat) {
    this.api.delete('Region/Delete/'+dat.sequenceid).subscribe((data: any) => {
      this.res = data; this.location.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postRegion() {
    this.api.post('Region/Post',this.loc).subscribe((data: any) => {
      this.getAllRegion(); this.formReset(); this.list = false;
      this.use.showMsg('success', 'Region Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Region');
    });
  }

  putRegion() {
    this.loc.muserid = this.use.active.id; this.loc.mdate = new Date();
    this.api.put('Region/Put/' + this.loc.id, this.loc).subscribe((data: any) => {
      this.res = data; this.getAllRegion();
      this.formReset(); this.list = false;
      this.use.showMsg('success', 'Region Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.loc = {
         countryid: null, 
         name: '', 
         code: '',
        userid: this.use.active.id, 
        date: new Date(), 
        muserid: null, 
        mdate: null
      };
    }
    this.edit = false;
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

   // delLoc(dat) {
  //   this._setup.delLocation(dat.locationid).subscribe((data: any) => {
  //     this.res = data; this.location.slice(dat);
  //   }, (err: HttpErrorResponse) => { alert(err.statusText); });
  // }

  // postLocation() {
  //   this._setup.postLocation(this.loc).subscribe((data: any) => {
  //     this.getAllLocation(); this.formReset(this.any); this.list = false;
  //     this.use.showMsg('success', 'Region Added', 'Added to list Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Region');
  //   });
  // }

   // putLocation() {
  //   this.loc.muserid = this.use.active.id; this.loc.mdate = new Date();
  //   this._setup.putLocation(this.loc.locationid, this.loc).subscribe((data: any) => {
  //     this.res = data; this.getAllLocation();
  //     this.formReset(this.any); this.list = false;
  //     this.use.showMsg('success', 'Region Updated', 'Details Updated Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
  //   });
  // }

}
