import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
declare let $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Paynow';

  // public img = config.imageUrl;
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if(window.location.host.includes('bj') || window.location.host.includes('benin')){
      translate.use('fr');
    } else {
      translate.use('en');
    }
  }
  
  ngOnInit(){
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    // $.getScript('assets/js/jquery-1.11.3.min.js');
    // $.getScript('assets/js/bootstrap-4.0.0.min.js');
    // // $.getScript('assets/js/plugins/jquery/jquery.min.js');
    // $.getScript('assets/js/preloader.js');

    // // $('.preloader-wrapper').fadeOut('slow');
    // $('body').addClass('preloader-site');
    // $('.preloader-wrapper').delay(1000).fadeOut("slow");
    // $('body').removeClass('preloader-site');

    // $(window).load(function() {
    //     $('.preloader-wrapper').delay(1000).fadeOut("slow");
    //     $('body').removeClass('preloader-site');
    //     // $('.footable').footable();
    // });
  }
}
