import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  isLoading: any = true;
  constructor(private loaderService: ApiService) {
    this.loaderService.Loading.subscribe(async data => {
      this.isLoading = await data;
    });
   }

  ngOnInit() {
  }

}
